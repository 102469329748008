import React from 'react';
import NoctilForRecordLabels from '../component/noctilForRecordLabels/NoctilForRecordLabels';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';
import CollaborateWithGlobal from '../component/collaborateWithGlobal/CollaborateWithGlobal';
import SuperChargeYourBusiness from '../component/superChargeYourBusiness/SuperChargeYourBusiness';
import AutomateCatalogRegistration from '../component/automateCatalogRegistration/AutomateCatalogRegistration';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PageHelmet from '../component/common/Helmet';

const MusicPublishersAndAdmins = () => {
  const { pathname } = useLocation();
  const isAdPage = pathname.includes(
    'metadata-management-software-for-music-publishers-and-record-labels'
  );
  return (
    <div>
      <PageHelmet
        pageTitle={'Noctil for Music Publishers and Administrators'}
        description={
          'Noctil platform can be easily integrated with in-house data models to maximize revenue.'
        }
      />
      <NoctilForRecordLabels
        subTitle={isAdPage ? '' : 'Noctil for Music Publishers and Administrators'}
        mainTitle="Achieve rapid catalog registration with music rights and streaming organizations."
        paragraph="Noctil platform can be easily integrated with in-house data models to maximize revenue."
        imgPath="/assets/images/redesignV2/32.png"
      />
      <AutomateCatalogRegistration />
      <CollaborateWithGlobal
        title="Connect with different agencies and societies"
        subTitle="Integrate with Noctil and improve royalty revenue"
      />
      <SuperChargeYourBusiness
        title="Supercharge your business with Noctil"
        subTitle="With lower implementation time and affordable costs, our platform is a
                no-code, supporting your data models and making your data instantly
                compatible for collaboration. Boost agility, speed up delivery, and
                maximize your revenue"
        imgPath="/assets/images/home-carousel/home-carousel-1.svg"
      />
      <ScheduleADemoSection />
    </div>
  );
};

export default MusicPublishersAndAdmins;
