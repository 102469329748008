import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './style.css';
import InfiniteLogoMarquee from './InfiniteLogoMarquee';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const societiesImages = [
  { src: 'assets/images/redesignV2/9.png', alt: 'Music Mark' },
  { src: 'assets/images/redesignV2/10.png', alt: 'ASCAP' },
  { src: 'assets/images/redesignV2/11.png', alt: 'SOCAN' },
  { src: 'assets/images/redesignV2/12.png', alt: 'THE MLC' },
  { src: 'assets/images/redesignV2/13.png', alt: 'Spotify' },
  { src: 'assets/images/redesignV2/14.png', alt: 'PRS for MUSIC' },
  { src: 'assets/images/redesignV2/15.png', alt: 'BMI' },
  { src: 'assets/images/redesignV2/16.png', alt: 'CISAC' },
  { src: 'assets/images/redesignV2/17.png', alt: 'SOUND EXCHANGE' },
  { src: 'assets/images/redesignV2/22.png', alt: 'LYRIC FIND' },
  { src: 'assets/images/redesignV2/23.png', alt: 'AIPA' },
  { src: 'assets/images/redesignV2/24.png', alt: 'ACTRA' },
];

const agenciesImages = [
  { src: 'assets/images/redesignV2/40.png', alt: 'Tune in' },
  { src: 'assets/images/redesignV2/41.png', alt: 'Youtube Music' },
  { src: 'assets/images/redesignV2/42.png', alt: 'SOUND CLOUD' },
  { src: 'assets/images/redesignV2/43.png', alt: 'APPLE MUSIC' },
  { src: 'assets/images/redesignV2/44.png', alt: 'ITUNES' },
  { src: 'assets/images/redesignV2/45.png', alt: 'AMAZON MUSIC' },
  { src: 'assets/images/redesignV2/46.png', alt: 'TIDAL' },
  { src: 'assets/images/redesignV2/47.png', alt: 'DEEZER' },
  { src: 'assets/images/redesignV2/48.png', alt: 'SHAZAM' },
  { src: 'assets/images/redesignV2/49.png', alt: 'SCAPR' },
  { src: 'assets/images/redesignV2/50.png', alt: 'DDEX' },
  { src: 'assets/images/redesignV2/51.png', alt: 'CWR' },
];

const CollaborateWithGlobal = ({ title, subTitle }) => {
  const { pathname } = useLocation();

  const isMusicRightSocietyPage = pathname.includes('music-rights-societies');
  return (
    <div className="">
      <div className="">
        <div className="pt--60 pb--60">
          <div className="col-lg-12 text-center">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2 className="font--54 text-dark">{title}</h2>
              <h3 className="text-dark">{subTitle}</h3>
            </ScrollAnimation>
          </div>
          <div className="col-lg-12 mt--20">
            <InfiniteLogoMarquee
              images={isMusicRightSocietyPage ? agenciesImages : societiesImages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborateWithGlobal;
