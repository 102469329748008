import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaLongArrowAltRight } from 'react-icons/fa';
import useGetBlogs from './useGetBlogs';

export const ResourcesForYou = () => {
  const { blogs, loading } = useGetBlogs();
  console.log({ blogs });
  return (
    <div className="resources-for-you">
      <div className="container">
        <div className="row ptb--60">
          <div className="col-lg-12">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2 className="text-center font--54">Resources for you</h2>
            </ScrollAnimation>
            <div className="flex-center pb--20">
              <div className="grid-center-cols--3 mt--20">
                {loading && <div>Loading...</div>}
                {blogs.map((blog) => (
                  <Card
                    key={blog?.id}
                    imgPath={
                      blog?.yoast_head_json?.og_image?.[0]?.url ||
                      '/assets/images/redesignV2/57.png'
                    }
                    type="Blog"
                    title={
                      blog?.title?.rendered ||
                      'Best ways to utilize music metadata for maximizing revenue'
                    }
                    onReadMoreClick={() => {
                      window.open(blog.link, '_blank');
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Card = ({ imgPath, type, title, onReadMoreClick }) => {
  return (
    <div className="card-wrapper" onClick={onReadMoreClick}>
      <img src={imgPath} alt="metadata" />
      <h6>{type}</h6>
      <h4>{title}</h4>
      <button onClick={onReadMoreClick}>
        <span>Read more </span>
        <FaLongArrowAltRight />
      </button>
    </div>
  );
};
