import React from 'react';
import Slider from 'react-slick';
import { blogCarousel, blogList } from '../../data/data';

const BlogCarousel = () => {
  return (
    <>
      <main className="page-wrapper">
        <div className="pt--90 pb--60 bg_color--1">
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20 blogCarousel">
                    <Slider {...blogCarousel}>
                      {blogList.map((value, index) => (
                        <div key={index}>
                          <div>
                            <a href={value.url}>
                              <img src={value.image} alt="Blogs - Noctil"></img>
                            </a>
                          </div>
                          <div className="content">
                            <p className="title">
                              <a href={value.url}>{value.title}</a>
                            </p>
                            <p className="publisedDate">{value.publisedDate}</p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default BlogCarousel;
