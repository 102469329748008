import React from 'react';
import { Card } from '../retain100PercentOfRights/Retain100PercentOfRights';
import ScrollAnimation from 'react-animate-on-scroll';

const StreamlineAndUpdateSystems = () => {
  return (
    <div className="bg-lightgray">
      <div className="container noctil-solutions">
        <div className="pt--60">
          <div className="row">
            <div className="col-lg-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>
                  Streamline, and update legacy systems to optimize metadata management{' '}
                </h2>
              </ScrollAnimation>
            </div>
          </div>
          <div className="grid-center-cols--3 plr--150">
            <Card
              imgPath={'/assets/images/redesignV2/25.png'}
              text={'Works and Repertoire Management'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/26.png'}
              text={'Member/ Rights holders Management'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/27.png'}
              text={'Playlist/Playlogs Management'}
            />
          </div>
          <div className="grid-center-cols--4">
            <Card
              imgPath={'/assets/images/redesignV2/28.png'}
              text={'Metadata Matching'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/29.png'}
              text={'Mandate Management'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/39.png'}
              text={'Bilateral Data Exchange'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/30.png'}
              text={'Distribution calculations & Payment reports'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamlineAndUpdateSystems;
