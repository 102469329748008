import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class OurJourney extends Component {
  render() {
    return (
      <div className="our-journey bg-gradient">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb--40">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>Our Journey So Far</h2>
              </ScrollAnimation>
            </div>
          </div>
          <div className="desktop-show">
            <div className="row">
              <div className="col-md-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2016</div>
                      <p>
                        Born as a subsidiary of Kongur Tiube LLC. Designed an initial MVP
                        for the matching algorithm.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2019</div>
                      <p>
                        Launched our first User Interface (UI) for managing diverse,
                        massive data workflows and data sources including music metadata,
                        artists' data, and much more!
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2022</div>
                      <p>
                        Launched Noctil Platform Version 2.0, a no-code, user-friendly,
                        affordable Data Management platform with highly customizable
                        plug-in modules and configurations.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-2">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2018</div>
                      <p>
                        Launched matchIQ, our flagship product, a fully automated, machine
                        learning based matching solution.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={250}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2020</div>
                      <p>
                        Launched Noctil Data Science and Analytical platform, an
                        API-driven, fully configurable data management tool that could
                        create AI/ML use cases, defining data sets, pipelines, and
                        visualization.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="mobile-show">
            <div className="row">
              <div className="col-lg-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2016</div>
                      <p>
                        Born as a subsidiary of Kongur Tiube LLC. Designed an initial MVP
                        for the matching algorithm.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2018</div>
                      <p>
                        Launched matchIQ, our flagship product, a fully automated, machine
                        learning based matching solution.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2019</div>
                      <p>
                        Launched our first User Interface (UI) for managing diverse,
                        massive data workflows and data sources including music metadata,
                        artists' data, and much more!
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2020</div>
                      <p>
                        Launched Noctil Data Science and Analytical platform, an
                        API-driven, fully configurable data management tool that could
                        create AI/ML use cases, defining data sets, pipelines, and
                        visualization.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={250}>
                  <div className="d-flex">
                    <img
                      src="/assets/images/icons/our-journey-icon.svg"
                      alt="About us - Noctil"
                    />
                    <div className="content">
                      <div className="year">2022</div>
                      <p>
                        Launched Noctil Platform Version 2.0, a no-code, user-friendly,
                        affordable Data Management platform with highly customizable
                        plug-in modules and configurations.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OurJourney;
