import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../component/common/Breadcrumb';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';

class Faqs extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Privacy Policy - Noctil"
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Privacy Policy'} />
        <div className="container">
          <div className="row">
            <div className="col-md-12 content">
              <h2>Privacy Notice</h2>
              <h5 className="mb--40">Last updated February 09, 2023</h5>
              <p>
                This privacy notice for Noctil ('<strong>Company</strong>', '
                <strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>'),
                describes how and why we might collect, store, use, and/or share
                ('process') your information when you use our services ('
                <strong>Services</strong>'), such as when you:
              </p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>
                        Visit our website at{' '}
                        <a href="https://www.noctil.com">https://www.noctil.com</a>
                      </li>
                      <li>
                        Engage with us in other related ways, including any sales,
                        marketing, or events
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <h5>Questions or concerns?</h5>
              <p className="mb--40">
                Reading this privacy notice will help you understand your privacy rights
                and choices. If you disagree with our policies and practices, please do
                not use our Services. If you still have any questions or concerns, please
                contact us at <a href="mailto:info@noctil.com">info@noctil.com.</a>
              </p>
              <h2>Summary of Key Points</h2>
              <h5 className="mb--20">
                This summary provides key points from our privacy notice, but you can find
                out more details about any of these topics by clicking the link following
                each key point or by using our table of contents below to find the section
                you are looking for. You can also click here to go directly to our table
                of contents.
              </h5>
              <h5>What personal information do we process?</h5>
              <p>
                When you visit, use, or navigate our Services, we may process personal
                information depending on how you interact with Noctil and the Services,
                the choices you make, and the products and features you use.
              </p>
              <h5>Do we process any sensitive personal information?</h5>
              <p>We do not process sensitive personal information.</p>
              <h5>Do we receive any information from third parties?</h5>
              <p>
                We may receive information from public databases, marketing partners,
                social media platforms, and other outside sources.
              </p>
              <h5>How do we process your information?</h5>
              <p>
                We process your information to provide, improve, and administer our
                Services, communicate with you for security and fraud prevention, and to
                comply with the law. We may also process your information for other
                purposes with your consent. We process your information only when we have
                a valid legal reason to do so.
              </p>
              <h5>
                In what situations and with which parties do we share personal
                information?
              </h5>
              <p>
                We may share information in specific situations and with specific third
                parties.
              </p>
              <h5>How do we keep your information safe?</h5>
              <p>
                We have organizational and technical processes and procedures in place to
                protect your personal information. However, no electronic transmission
                over the internet or information storage technology can be guaranteed to
                be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorized third parties will not be able to
                defeat our security and improperly collect, access, steal, or modify your
                information.
              </p>
              <h5>What are your rights?</h5>
              <p>
                Depending on where you are located geographically, the applicable privacy
                law may mean you have certain rights regarding your personal information.
              </p>
              <h5>How do you exercise your rights?</h5>
              <p>
                The easiest way to exercise your rights is by filling out our data subject
                request form, available here:{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a>, or by contacting us.
                We will consider and act upon any request in accordance with applicable
                data protection laws.
              </p>
              <p className="mb--40">
                Want to learn more about what Noctil does with any information we collect?
              </p>
              <h2>Table of Contents</h2>
              <div className="service-details-inner mb--60">
                <div className="service-details-content">
                  <div className="details">
                    <ol className="liststyle">
                      <li>1. What information do we collect?123</li>
                      <li>2. How do we process your information?</li>
                      <li>
                        3. What legal bases do we rely on to process your personal
                        information?
                      </li>
                      <li>
                        4. When and with whom do we share your personal information?
                      </li>
                      <li>5. How do we handle your social logins?</li>
                      <li>6. How long do we keep your information?</li>
                      <li>7. How do we keep your information safe?</li>
                      <li>8. Do we collect information from minors?</li>
                      <li>9. What are your privacy rights?</li>
                      <li>10. Controls for do-not-track features</li>
                      <li>11. Do california residents have specific privacy rights?</li>
                      <li>12. Do virginia residents have specific privacy rights?</li>
                      <li>13. Do we make updates to this notice?</li>
                      <li>14. How can you contact us about this notice?</li>
                      <li>
                        15. How can you review, update, or delete the data we collect from
                        you?
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <h5 className="color-primary mb--20">1. What information do we collect?</h5>
              <h5 className="mb--20">The personal information you disclose to us</h5>
              <p>
                <strong>In Short: </strong> We collect personal information that you
                provide to us.<br></br>
                We collect personal information that you voluntarily provide to us when
                you express an interest in obtaining information about us or our products
                and Services, when you participate in activities on the Services, or
                otherwise when you contact us.
              </p>
              <p>
                <strong>Personal Information Provided by You. </strong> The personal
                information that we collect depends on the context of your interactions
                with us and the Services, the choices you make, and the products and
                features you use. The personal information we collect may include the
                following:
              </p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>names</li>
                      <li>phone numbers</li>
                      <li>email addresses</li>
                      <li>job titles</li>
                      <li>company name</li>
                      <li>contact preferences</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                <strong>Sensitive Information. </strong> We do not process sensitive
                information.
              </p>
              <p>
                <strong>Social Media Login Data. </strong> We may provide you with the
                option to register with us using your existing social media accounts
                details, like your Facebook, Twitter, or other social media account. If
                you choose to register in this way, we will collect the information
                described in the section called 'How do we handle your social logins?''
                below.
              </p>
              <p>
                All personal information that you provide to us must be true, complete,
                and accurate, and you must notify us of any changes to such personal
                information.
              </p>
              <h5 className="mb--20">Information automatically collected</h5>
              <p>
                <strong>In Short: </strong> Some information — such as your Internet
                Protocol (IP) address and/or browser and device characteristics — is
                collected automatically when you visit our services.<br></br>We
                automatically collect certain information when you visit, use, or navigate
                the services. This information does not reveal your specific identity
                (like your name or contact information) but may include device and usage
                information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name,
                country, location, information about how and when you use our Services,
                and other technical information. This information is primarily needed to
                maintain the security and operation of our Services and for our internal
                analytics and reporting purposes.<br></br>The information we collect
                includes:
              </p>
              <p>
                <strong>Log and Usage Data. </strong> Log and usage data are
                service-related, diagnostic, usage, and performance information our
                servers automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact with us, this
                log data may include your IP address, device information, browser type,
                and settings and information about your activity in the Services (such as
                the date/time stamps associated with your usage, pages, and files viewed,
                searches, and other actions you take such as which features you use),
                device event information (such as system activity, error reports
                (sometimes called 'crash dumps'), and hardware settings).
              </p>
              <p>
                <strong>Device Data. </strong> We collect device data such as information
                about your computer, phone, tablet, or other devices you use to access the
                Services. Depending on the device used, this device data may include
                information such as your IP address (or proxy server), device and
                application identification numbers, location, browser type, hardware
                model, Internet service provider and/or mobile carrier, operating system,
                and system configuration information.
              </p>
              <p>
                <strong>Location Data. </strong> We collect location data, such as
                information about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type and
                settings of the device you use to access the Services. For example, we may
                use GPS and other technologies to collect geolocation data that tells us
                your current location (based on your IP address). You can opt out of
                allowing us to collect this information either by refusing access to the
                information or by disabling your Location setting on your device. However,
                if you choose to opt out, you may not be able to use certain aspects of
                the Services.
              </p>
              <h5>Information collected from other sources</h5>
              <p className="mb--60">
                <strong>In Short: </strong> We may collect limited data from public
                databases, marketing partners, social media platforms, and other outside
                sources.<br></br>In order to enhance our ability to provide relevant
                marketing, offers, and services to you and update our records, we may
                obtain information about you from other sources, such as public databases,
                joint marketing partners, affiliate programs, data providers, social media
                platforms, and from other third parties. This information includes mailing
                addresses, job titles, email addresses, phone numbers, intent data(or user
                behavior data), Internet Protocol (IP) addresses, social media profiles,
                social media URLs, and custom profiles for purposes of targeted
                advertising and event promotion. If you interact with us on a social media
                platform using your social media account (e.g., Facebook or Twitter), we
                receive personal information about you, such as your name, email address,
                and gender. Any personal information that we collect from your social
                media account depends on your social media account privacy settings.
              </p>
              <h5 className="color-primary mb--20">
                2. How do we process your information?
              </h5>
              <p>
                <strong>In Short: </strong> We process your information to provide,
                improve, and administer our services, communicate with you for security
                and fraud prevention, and to comply with the law. We may also process your
                information for other purposes with your consent. We process your personal
                information for a variety of reasons, depending on how you interact with
                our Services, including:
              </p>
              <div className="service-details-inner mb--60">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>
                        To deliver and facilitate the delivery of services to the user. We
                        may process your information to provide you with the requested
                        service.
                      </li>
                      <li>
                        To respond to user inquiries/offer support to users. We may
                        process your information to respond to your inquiries and solve
                        any potential issues you might have with the requested service.
                      </li>
                      <li>
                        To fulfil and manage your orders. We may process your information
                        to fulfil and manage your orders, payments, returns, and exchanges
                        made through the Services.
                      </li>
                      <li>
                        To enable user-to-user communications. We may process your
                        information if you choose to use any of our offerings that allow
                        for communication with another user.
                      </li>
                      <li>
                        To save or protect an individual's vital interest. We may process
                        your information when necessary to save or protect an individual's
                        vital interest, such as to prevent harm.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <h5 className="color-primary mb--20">
                3. What legal bases do we rely on to process your personal information?
              </h5>
              <p>
                <strong>In Short: </strong> We only process your personal information when
                we believe it is necessary and we have a valid legal reason (i.e., legal
                basis) to do so under applicable law, like with your consent, to comply
                with laws, to provide you with services to enter into or fulfill our
                contractual obligations, to protect your rights, or to fulfill our
                legitimate business interests.
              </p>
              <p>
                If you are located in the EU or UK, this section applies to you.<br></br>
                The General Data Protection Regulation (GDPR) and UK GDPR require us to
                explain the valid legal bases we rely on to process your personal
                information. As such, we may rely on the following legal bases to process
                your personal information:
              </p>
              <p>
                Consent. We may process your information if you have given us permission
                (i.e., consent) to use your personal information for a specific purpose.
                You can withdraw your consent at any time.
              </p>
              <p>
                Performance of a Contract. We may process your personal information when
                we believe it is necessary to fulfill our contractual obligations to you,
                including providing our Services or at your request prior to entering into
                a contract with you.
              </p>
              <p>
                Legal Obligations. We may process your information where we believe it is
                necessary for compliance with our legal obligations, such as to cooperate
                with a law enforcement body or regulatory agency, exercise or defend our
                legal rights, or disclose your information as evidence in litigation in
                which we are involved.
              </p>
              <p>
                Vital Interests. We may process your information where we believe it is
                necessary to protect your vital interests or the vital interests of a
                third party, such as in situations involving potential threats to the
                safety of any person.
              </p>
              <p>
                If you are located in Canada, this section applies to you.<br></br>We may
                process your information if you have given us specific permission (i.e.,
                Express consent) to use your personal information for a specific purpose
                or in situations where your permission can be inferred (i.e., implied
                consent). You can withdraw your consent at any time.
              </p>
              <p>
                In some exceptional cases, we may be legally permitted under applicable
                law to process your information without your consent, including, for
                example:
              </p>
              <div className="service-details-inner mb--60">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>
                        If collection is clearly in the interests of an individual and
                        consent cannot be obtained in a timely way
                      </li>
                      <li>For investigations and fraud detection, and prevention</li>
                      <li>
                        For business transactions, provided certain conditions are met
                      </li>
                      <li>
                        If it is contained in a witness statement and the collection is
                        necessary to assess, process, or settle an insurance claim
                      </li>
                      <li>
                        For identifying injured, ill, or deceased persons and
                        communicating with next of kin
                      </li>
                      <li>
                        If we have reasonable grounds to believe an individual has been,
                        is or may be a victim of financial abuse
                      </li>
                      <li>
                        If it is reasonable to expect collection and use with consent
                        would compromise the availability or the accuracy of the
                        information and the collection is reasonable for purposes related
                        to investigating a breach of an agreement or a contravention of
                        the laws of Canada or a province
                      </li>
                      <li>
                        If disclosure is required to comply with a subpoena, warrant,
                        court order, or rules of the court relating to the production of
                        records
                      </li>
                      <li>
                        If an individual produced it in the course of their employment,
                        business, or profession, and the collection is consistent with the
                        purposes for which the information was produced
                      </li>
                      <li>
                        If the collection is solely for journalistic, artistic, or
                        literary purposes
                      </li>
                      <li>
                        If the information is publicly available and is specified by the
                        regulations
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <h5 className="color-primary mb--20">
                4. When and with whom do we share your personal information?
              </h5>
              <p>
                <strong>In Short:</strong> We may share information in specific situations
                described in this section and/or with the following third parties.
              </p>
              <p className="mb--60">
                We may need to share your personal information in the following
                situations:<br></br>Business Transfers. We may share or transfer your
                information in connection with, or during negotiations of, any merger,
                sale of company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </p>
              <h5 className="color-primary mb--20">
                5. How do we handle your social logins?
              </h5>
              <p>
                <strong>In Short: </strong> If you choose to register or log in to our
                Services using a social media account, we may have access to certain
                information about you.
              </p>
              <p>
                Our Services offer you the ability to register and log in using your
                third-party social media account details (like your Facebook or Twitter
                logins). Where you choose to do this, we will receive certain profile
                information about you from your social media provider. The profile
                information we receive may vary depending on the social media provider
                concerned but will often include your name, email address, friends list,
                and profile picture, as well as other information you choose to make
                public on such social media platforms.
              </p>
              <p className="mb--60">
                We will use the information we receive only for the purposes that are
                described in this privacy notice or that are otherwise made clear to you
                on the relevant Services. Please note that we do not control and are not
                responsible for other uses of your personal information by your
                third-party social media provider. We recommend that you review their
                privacy notice to understand how they collect, use, and share your
                personal information and how you can set your privacy preferences on their
                sites and apps.
              </p>
              <h5 className="color-primary mb--20">
                6. How long do we keep your information?
              </h5>
              <p>
                <strong>In Short: </strong> We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </p>
              <p className="mb--60">
                {' '}
                We will only keep your personal information for as long as it is necessary
                for the purposes set out in this privacy notice unless a longer retention
                period is required or permitted by law (such as tax, accounting, or other
                legal requirements). No purpose in this notice will require us to keep
                your personal information for longer than two years. When we have no
                ongoing legitimate business need to process your personal information, we
                will either delete or anonymize such information or, if this is not
                possible (for example, because your personal information has been stored
                in backup archives), then we will securely store your personal information
                and isolate it from any further processing until deletion is possible.
              </p>
              <h5 className="color-primary mb--20">
                7. How do we keep your information safe?
              </h5>
              <p>
                <strong>In Short: </strong> We aim to protect your personal information
                through a system of organizational and technical security measures.
              </p>
              <p className="mb--60">
                We have implemented appropriate and reasonable technical and
                organizational security measures designed to protect the security of any
                personal information we process. However, despite our safeguards and
                efforts to secure your information, no electronic transmission over the
                Internet or information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers, cybercriminals, or
                other unauthorized third parties will not be able to defeat our security
                and improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal information, the
                transmission of personal information to and from our Services is at your
                own risk. You should only access the services within a secure environment.
              </p>
              <h5 className="color-primary mb--20">
                8. Do we collect information from minors?
              </h5>
              <p>
                <strong>In Short: </strong> We do not knowingly collect data from or
                market to children under 18 years of age.
              </p>
              <p className="mb--60">
                We do not knowingly solicit data from or market to children under 18 years
                of age. By using the Services, you represent that you are at least 18 or
                that you are the parent or guardian of such a minor and consent to such
                minor dependent's use of the Services. If we learn that personal
                information from users less than 18 years of age has been collected, we
                will deactivate the account and take reasonable measures to promptly
                delete such data from our records. If you become aware of any data we may
                have collected from children under the age 18, please contact us at{' '}
                <a href="mailto:info@noctil.com">info@noctil.com.</a>
              </p>
              <h5 className="color-primary mb--20">9. What are your privacy rights?</h5>
              <p>
                <strong>In Short: </strong> In some regions, such as the European Economic
                Area (EEA), United Kingdom (UK), and Canada, you have rights that allow
                you greater access to and control over your personal information. You may
                review, change, or terminate your account at any time.
              </p>
              <p>
                In some regions (like the EEA, UK, and Canada), you have certain rights
                under applicable data protection laws. These may include the right (i) to
                request access and obtain a copy of your personal information, (ii) to
                request rectification or erasure; (iii)to restrict the processing of your
                personal information; and (iv), if applicable, to data portability. In
                certain circumstances, you may also have the right to object to the
                processing of your personal information. You can make such a request by
                contacting us by using the contact details provided in the section 'How
                can you contact us about this notice?' below.
              </p>
              <p>
                We will consider and act upon any request in accordance with applicable
                data protection laws.
              </p>
              <p>
                If you are located in the EEA or UK, and you believe we are unlawfully
                processing your personal information, you also have the right to complain
                to your local data protection supervisory authority. You can find their
                contact details here:{' '}
                <a
                  href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                </a>
              </p>
              <p>
                If you are located in Switzerland, the contact details for the data
                protection authorities are available here:{' '}
                <a
                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.edoeb.admin.ch/edoeb/en/home.html.
                </a>
              </p>
              <p>
                Withdrawing your consent: If we rely on your consent to process your
                personal information, which may be express and/or implied consent,
                depending on the applicable law, you have the right to withdraw your
                consent at any time. You can withdraw your consent at any time by
                contacting us by using the contact details provided in the section 'How
                can you contact us about this notice?' below.
              </p>
              <p>
                However, please note that this will not affect the lawfulness of the
                processing before its withdrawal nor, when applicable law allows, will it
                affect the processing of your personal information conducted in reliance
                on lawful processing grounds other than consent.
              </p>
              <p>
                Opting out of marketing and promotional communications: You can
                unsubscribe from our marketing and promotional communications at any time
                by clicking on the unsubscribe link in the emails that we send, sending an
                email to <a href="mailto:info@noctil.com">info@noctil.com</a>, or
                contacting us at (703) 991-9982, or by contacting us using the details
                provided in the section 'How can you contact us about this notice?' below.
                You will then be removed from the marketing lists. However, we may still
                communicate with you —for example, to send you service-related messages
                that are necessary for the administration and use of your account, to
                respond to service requests, or for other non-marketing purposes.
              </p>
              <p className="mb--60">
                If you have questions or comments about your privacy rights, you may email
                us at <a href="mailto:info@noctil.com">info@noctil.com.</a>
              </p>
              <h5 className="color-primary mb--20">
                10. Controls for do-not-track features
              </h5>
              <p className="mb--60">
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ('DNT') feature or setting you can
                activate to signal your privacy preference not to have data about your
                online browsing activities monitored and collected. At this stage, no
                uniform technology standard for recognizing and implementing DNT signals
                has been finalized. As such, we do not currently respond to DNT browser
                signals or any other mechanism that automatically communicates your choice
                not to be tracked online. If a standard for online tracking is adopted
                that we must follow in the future, we will inform you about that practice
                in a revised version of this privacy notice.
              </p>
              <h5 className="color-primary mb--20">
                11. Do California residents have specific privacy rights?
              </h5>
              <p>
                <strong>In Short: </strong> Yes, if you are a resident of California, you
                are granted SPECIFIC PRIVACY specific rights regarding access to your
                personal information.
              </p>
              <p>
                California Civil Code Section 1798.83, also known as the 'Shine The Light'
                law, permits our users who are California residents to request and obtain
                from us, once a year and free of charge, information about categories of
                personal information (if any)we disclosed to third parties for direct
                marketing purposes and the names and addresses of all third parties with
                which we shared personal information the immediately preceding calendar
                year. If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the contact
                information provided below.
              </p>
              <p>
                If you are under 18 years of age, reside in California, and have a
                registered account with Services, you have the right to request the
                removal of unwanted data that you publicly post on the Services. To
                request the removal of such data, please contact us using the contact
                information provided below and include the email address associated with
                your account and a statement that you reside in California. We will ensure
                the data is not publicly displayed on the Services, but please be aware
                that the data may not be completely or comprehensively removed from all
                our systems (e.g., backups, etc.).
              </p>
              <p>
                CCPA Privacy Notice<br></br>The California Code of Regulations defines a
                'resident' as:<br></br>
                (1) every individual who is in the State of California for other than a
                temporary or transitory purpose and<br></br>(2) every individual who is
                domiciled in the State of California who is outside the State of
                California for a temporary or transitory purpose
              </p>
              <p>All other individuals are defined as 'non-residents'.</p>
              <p>
                If this definition of 'resident' applies to you, we must adhere to certain
                rights and obligations regarding your personal information.
              </p>
              <p>
                What categories of personal information do we collect?<br></br>We have
                collected the following categories of personal information in the past
                twelve(12) months:
              </p>
              <div class="table-responsive mb--20">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        Category
                      </th>
                      <th scope="col" className="text-center">
                        Example
                      </th>
                      <th scope="col" className="text-center">
                        Collected
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A. Identifiers</td>
                      <td>
                        Contact details, such as real name, alias, postal address,
                        telephone or mobile contact number, unique personal identifier,
                        online identifier, Internet Protocol address, email address, and
                        account name
                      </td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>
                        B. Personal information categories listed in the California
                        Customer Records statute
                      </td>
                      <td>
                        Name, contact information, education, employment, employment
                        history, and financial information
                      </td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>
                        C. Protected classification characteristics under California or
                        federal law
                      </td>
                      <td>Gender and date of birth</td>
                      <td className="text-center">No</td>
                    </tr>
                    <tr>
                      <td>D. Commercial information</td>
                      <td>
                        Transaction information, purchase history, financial details, and
                        payment information
                      </td>
                      <td className="text-center">No</td>
                    </tr>
                    <tr>
                      <td>E. Biometric information</td>
                      <td>Fingerprints and voiceprints</td>
                      <td className="text-center">No</td>
                    </tr>
                    <tr>
                      <td>F. Internet or other similar network activity</td>
                      <td>
                        Browsing history, search history, online behaviour, interest data,
                        and interactions with our and other websites, applications,
                        systems, and advertisements
                      </td>
                      <td className="text-center">No</td>
                    </tr>
                    <tr>
                      <td>G. Geolocation data</td>
                      <td>Device location</td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>
                        H. Audio, electronic, visual, thermal, olfactory, or similar
                        information
                      </td>
                      <td>
                        Images and audio, video, or call recordings created in connection
                        with our business activities
                      </td>
                      <td className="text-center">No</td>
                    </tr>
                    <tr>
                      <td>I. Professional or employment-related information</td>
                      <td>
                        Business contact details in order to provide you our Services at a
                        business level or job title, work history, and professional
                        qualifications if you apply for a job with us
                      </td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>J. Education Information</td>
                      <td>Student records and directory information</td>
                      <td className="text-center">No</td>
                    </tr>
                    <tr>
                      <td>K. Inferences drawn from other personal information</td>
                      <td>
                        Inferences are drawn from any of the collected personal
                        information listed above to create a profile or summary about, for
                        example, an individual's preferences and characteristics
                      </td>
                      <td className="text-center">Yes</td>
                    </tr>
                    <tr>
                      <td>L. Sensitive personal information</td>
                      <td></td>
                      <td className="text-center">No</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                We will use and retain the collected personal information as needed to
                provide the services or for:
              </p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>Category A - As long as the user has an account with us</li>
                      <li>Category B - As long as the user has an account with us</li>
                      <li>Category G - As long as the user has an account with us</li>
                      <li>Category I - As long as the user has an account with us</li>
                      <li>Category K - As long as the user has an account with us</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                We may also collect other personal information outside of these categories
                through instances where you interact with us in person, online, or by
                phone or mail in the context of:
              </p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>Receiving help through our customer support channels;</li>
                      <li>Participation in customer surveys or contests; and</li>
                      <li>
                        Facilitation in the delivery of our Services and responding to
                        your inquiries.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="color-primary">
                How do we use and share your personal information?
              </p>
              <p>
                More information about our data collection and sharing practices can be
                found in this privacy notice.
              </p>
              <p>
                You may contact us by email at{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a> or by referring to
                the contact details at the bottom of this document.
              </p>
              <p>
                If you are using an authorized agent to exercise your right to opt-out, we
                may deny a request if the authorized agent does not submit proof that they
                have been validly authorized to act on your behalf.
              </p>
              <p className="color-primary">
                Will your information be shared with anyone else?
              </p>
              <p>
                We may disclose your personal information with our service providers
                pursuant to a written contract between each service provider and us. Each
                service provider is a for-profit entity that processes the information on
                our behalf, following the same strict privacy protection obligations
                mandated by the CCPA.
              </p>
              <p>
                We may use your personal information for our own business purposes, such
                as for undertaking internal research for technological development and
                demonstration. This is not considered to be 'selling' of your personal
                information.
              </p>
              <p>
                Noctil has not disclosed, sold, or shared any personal information to
                third parties for business or commercial purposes in the preceding twelve
                (12) months. Noctil will not sell or share personal information in the
                future belonging to website visitors, users, and other consumers.
              </p>
              <p className="color-primary">
                Your rights with respect to your personal data
              </p>
              <p className="txt-underline">
                Right to request deletion of the data — Request to delete
              </p>
              <p>
                You can ask for the deletion of your personal information. If you ask us
                to delete your personal information, we will respect your request and
                delete your personal information, subject to certain exceptions provided
                by law, such as (but not limited to)the exercise by another consumer of
                his or her right to free speech, our compliance requirements resulting
                from a legal obligation, or any processing that may be required to protect
                against illegal activities.
              </p>
              <p className="txt-underline">Right to be informed — Request to know</p>
              <p>Depending on the circumstances, you have a right to know:</p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>whether we collect and use your personal information;</li>
                      <li>the categories of personal information that we collect;</li>
                      <li>
                        the purposes for which the collected personal information is used;
                      </li>
                      <li>
                        whether we sell or share personal information to third parties;
                      </li>
                      <li>
                        the categories of personal information that we sold, shared, or
                        disclosed for a business purpose;
                      </li>
                      <li>
                        the categories of third parties to whom the personal information
                        was sold, shared, or disclosed for a business purpose;
                      </li>
                      <li>
                        the business or commercial purpose for collecting, selling, or
                        sharing personal information; and
                      </li>
                      <li>
                        the specific pieces of personal information we collected about
                        you.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                In accordance with applicable law, we are not obligated to provide or
                delete consumer information that is de-identified in response to a
                consumer request or to re-identify individual data to verify a consumer
                request.
              </p>
              <p className="txt-underline">
                Right to Non-Discrimination for the Exercise of a Consumer's Privacy
                Rights
              </p>
              <p>
                We will not discriminate against you if you exercise your privacy rights.
              </p>
              <p className="txt-underline">
                Right to Limit Use and Disclosure of Sensitive Personal Information
              </p>
              <p>We do not process consumer's sensitive personal information.</p>
              <p className="txt-underline">Verification process</p>
              <p>
                Upon receiving your request, we will need to verify your identity to
                determine you are the same person about whom we have the information in
                our system. These verification efforts require us to ask you to provide
                information so that we can match it with information you have previously
                provided us. For instance, depending on the type of request you submit, we
                may ask you to provide certain information so that we can match the
                information you provide with the information we already have on file, or
                we may contact you through a communication method (e.g. phone or email)
                that you have previously provided to us. We may also use other
                verification methods as the circumstances dictate.
              </p>
              <p>
                We will only use personal information provided in your request to verify
                your identity or authority to make the request. To the extent possible, we
                will avoid requesting additional information from you for the purposes of
                verification. However, if we cannot verify your identity from the
                information already maintained by us, we may request that you provide
                additional information for the purposes of verifying your identity and for
                security or fraud-prevention purposes. We will delete such additionally
                provided information as soon as we finish verifying you.
              </p>
              <p className="txt-underline">Other privacy rights</p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>
                        You may object to the processing of your personal information.
                      </li>
                      <li>
                        You may request correction of your personal data if it is
                        incorrect or no long errelevant or ask to restrict the processing
                        of the information.
                      </li>
                      <li>
                        You can designate an authorized agent to make a request under the
                        CCPA on your behalf. We may deny a request from an authorized
                        agent that does not submit proof that they have been validly
                        authorized to act on your behalf in accordance with the CCPA.
                      </li>
                      <li>
                        You may request to opt out from future selling or sharing of your
                        personal information to third parties. Upon receiving an opt-out
                        request, we will act upon the request as soon as feasibly
                        possible, but no later than fifteen (15) days from the date of the
                        request submission.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="mb--60">
                To exercise these rights, you can contact us by email at{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a>, or by referring to
                the contact details at the bottom of this document. If you have a
                complaint about how we handle your data, we would like to hear from you.
              </p>
              <h5 className="color-primary mb--20">
                12. Do virginia residents have specific privacy rights?
              </h5>
              <p>
                <strong>In Short: </strong> Yes, if you are a resident of Virginia, you
                may be granted specific rights regarding access to and use of your
                personal information.
              </p>
              <p>Virginia CDPA Privacy Notice</p>
              <p>
                Under the Virginia Consumer Data Protection Act (CDPA):<br></br>'Consumer'
                means a natural person who is a resident of the Commonwealth acting only
                in an individual or household context. It does not include a natural
                person acting in a commercial or employment context.<br></br>'Personal
                data' means any information that is linked or reasonably linkable to an
                identified or identifiable natural person. 'Personal data' does not
                include de-identified data or publicly available information.<br></br>
                'Sale of personal data' means the exchange of personal data for monetary
                consideration.<br></br>If this definition 'consumer' applies to you, we
                must adhere to certain rights and obligations regarding your personal
                data.<br></br>The information we collect, use, and disclose about you will
                vary depending on how you interact with Noctil and our Services. To find
                out more, please visit the following links:
              </p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>Personal data we collect</li>
                      <li>How we use your personal data</li>
                      <li>When and with whom we share your personal data</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="txt-underline">
                Your rights with respect to your personal data
              </p>
              <div className="service-details-inner">
                <div className="service-details-content">
                  <div className="details">
                    <ul className="liststyle">
                      <li>
                        Right to be informed whether or not we are processing your
                        personal data
                      </li>
                      <li>Right to access your personal data</li>
                      <li>Right to correct inaccuracies in your personal data</li>
                      <li>Right to request deletion of your personal data</li>
                      <li>
                        Right to obtain a copy of the personal data you previously shared
                        with us
                      </li>
                      <li>
                        Right to opt out of the processing of your personal data if it is
                        used for targeted advertising, the sale of personal data, or
                        profiling in furtherance of decisions that produce legal or
                        similarly significant effects ('profiling')
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                Noctil has not sold any personal data to third parties for business or
                commercial purposes. Noctil will not sell personal data in the future
                belonging to website visitors, users, and other consumers.
              </p>
              <p className="txt-underline">
                Exercise your rights provided under the Virginia CDPA
              </p>
              <p>
                More information about our data collection and sharing practices can be
                found in this privacy notice.
              </p>
              <p>
                You may contact us by email at{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a>, by visiting{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a>, or by referring to
                the contact details at the bottom of this document.
              </p>
              <p>
                If you are using an authorized agent to exercise your rights, we may deny
                a request if the authorized agent does not submit proof that they have
                been validly authorized to act on your behalf.
              </p>
              <p className="txt-underline">Verification process</p>
              <p>
                We may request that you provide additional information reasonably
                necessary to verify your and your consumer's request. If you submit the
                request through an authorized agent, we may need to collect additional
                information to verify your identity before processing your request.
              </p>
              <p>
                Upon receiving your request, we will respond without undue delay, but in
                all cases, within forty-five (45) days of receipt. The response period may
                be extended once by forty-five (45) additional days when reasonably
                necessary. We will inform you of any such extension within the initial
                45-day response period, together with the reason for the extension.
              </p>
              <p className="txt-underline">Right to appeal</p>
              <p className="mb--60">
                If we decline to take action regarding your request, we will inform you of
                our decision and its reasoning. If you wish to appeal our decision, please
                email us at <a href="mailto:info@noctil.com">info@noctil.com</a>. Within
                sixty (60) days of receipt of an appeal, we will inform you in writing of
                any action taken or not taken in response to the appeal, including a
                written explanation of the reasons for the decisions. If your appeal is
                denied, you may contact the Attorney General to submit a complaint.
              </p>
              <h5 className="color-primary mb--20">
                13. Do we make updates to this notice?
              </h5>
              <p>
                <strong>In Short: </strong> Yes, we will update this notice as necessary
                to stay compliant with relevant laws.
              </p>
              <p className="mb--60">
                We may update this privacy notice from time to time. The updated version
                will be indicated by an updated 'Revised' date, and the updated version
                will be effective as soon as it is accessible. If we make material changes
                to this privacy notice, we may notify you either by prominently posting a
                notice of such changes or by directly sending you a notification. We
                encourage you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
              <h5 className="color-primary mb--20">
                14. How can you contact us about this notice?
              </h5>
              <p className="mb--60">
                If you have questions or comments about this notice, you may email us at{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a> or by post to:
                <br></br>Noctil - 42395 Ryan Road<br></br>Suite 112-659<br></br>Ashburn,
                Virginia2/9/23<br></br>Ashburn, VA 20148<br></br>United States
              </p>
              <h5 className="color-primary mb--20">
                15. How can you review, update, or delete the data we collect from you?
              </h5>
              <p>
                You have the right to request access to the personal information we
                collect from you, change that information, or delete it. To request to
                review, update, or delete your personal information, please visit{' '}
                <a href="mailto:info@noctil.com">info@noctil.com</a>.
              </p>
            </div>
          </div>
        </div>
        <div className="mt--40">
          <ScheduleADemoSection />
        </div>
      </React.Fragment>
    );
  }
}
export default Faqs;
