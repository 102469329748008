import { useEffect } from 'react';

const useStickyHeader = () => {
  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY;
      const header = document.querySelector('.header--fixed');
      if (header) {
        if (value > 100) {
          header.classList.add('sticky');
        } else {
          header.classList.remove('sticky');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useStickyHeader;
