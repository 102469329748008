import React from 'react';
import Marquee from 'react-fast-marquee';

const InfiniteLogoMarquee = ({ images }) => {
  return (
    <div className="logos-marquee-wrapper">
      <Marquee pauseOnHover speed={100} autoFill>
        {images.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} />
        ))}
      </Marquee>
    </div>
  );
};

export default InfiniteLogoMarquee;
