import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class AboutBanner extends Component {
  render() {
    return (
      <div className="container">
        <div className="aboutBannerOuter">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
            <div className="row">
              <div className="col-lg-6">
                <p className="title">
                  Empowering You to Uncover Revenue Opportunities from Large Volumes of
                  Data
                </p>
                <p>
                  Founded in 2016 as a subsidiary of Kongur Tiube LLC., Noctil has come a
                  long way in delivering intelligent yet scalable data and cloud solutions
                  for the music and movie industry. We leverage the power of artificial
                  intelligence, machine learning, and cloud technology to create better
                  experiences and cost advantages for our customers.
                </p>
                <p>
                  With solid industry knowledge and a strong foundation for
                  problem-solving and innovation, we transform the way you manage and
                  utilize data. We ensure that you seamlessly evolve with the changing
                  future of the industry, taking complete control of your music and movie
                  metadata, royalties, rights, catalog, and much more without any hassle!
                </p>
              </div>
              <div className="col-lg-6 bannerimg text-center d-flex align-items-center">
                <img src="/assets/images/about/about.svg" alt="About us - Noctil" />
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}
export default AboutBanner;
