import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useHistory } from 'react-router-dom';

const SolutionsListItems = ({ title, icon, description, url }) => {
  const history = useHistory();
  return (
    <div className="container">
      <div className="row solutionItem flex-column-reverse flex-md-row">
        <div className="col-md-4 image-block">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
            <img src={'/assets/images/' + icon} alt={'Solutions'} />
          </ScrollAnimation>
        </div>
        <div className="col-md-8">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
            <div
              className="title"
              onClick={() => {
                history.push(url);
              }}
            >
              {title}
            </div>
            <p>{description}</p>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};
export default SolutionsListItems;
