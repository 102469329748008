import React from 'react';
import NoctilForRecordLabels from '../component/noctilForRecordLabels/NoctilForRecordLabels';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';
import CollaborateWithGlobal from '../component/collaborateWithGlobal/CollaborateWithGlobal';
import SuperChargeYourBusiness from '../component/superChargeYourBusiness/SuperChargeYourBusiness';
import StreamlineAndUpdateSystems from '../component/streamlineAndUpdateSystems/StreamlineAndUpdateSystems';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PageHelmet from '../component/common/Helmet';

const MusicRightsSocieties = () => {
  const { pathname } = useLocation();
  const isAdPage = pathname.includes('software-for-music-rights-societies');

  return (
    <div>
      <PageHelmet
        pageTitle={'Noctil for Music Rights Societies'}
        description={
          'With Noctil, eliminate redundant entries faster, and achieve accurate data to maximize royalty payouts'
        }
      />
      <NoctilForRecordLabels
        subTitle={isAdPage ? '' : 'Noctil for Music Rights Societies'}
        mainTitle="Handle complex metadata matching effectively."
        imgPath="/assets/images/redesignV2/38.png"
        paragraph="With Noctil, eliminate redundant entries faster, and achieve accurate data to maximize royalty payouts"
      />
      <StreamlineAndUpdateSystems />
      <CollaborateWithGlobal
        title="Connect with different agencies"
        subTitle="Integrate with Noctil to handle the complexities of digital music distribution"
      />
      <SuperChargeYourBusiness
        title="Boost Your Royalty payouts"
        subTitle="Noctil enhances Distribution Rate with improved data quality and Smart Matching leading to 98% increase in royalty payments for our clients. We can handle it all, whether you're dealing with songs, videos, playlists, or performer info. Let Noctil help you improve revenue and boost artists’ growth and income, faster and more accurately."
        imgPath="/assets/images/home-carousel/home-carousel-2.svg"
      />
      <ScheduleADemoSection />
    </div>
  );
};

export default MusicRightsSocieties;
