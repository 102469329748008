import React, { Component } from 'react';
import PageHelmet from '../../component/common/Helmet';
import Breadcrumb from '../../component/common/Breadcrumb';
import ScheduleADemoSection from '../../component/scheduleADemoSection/ScheduleADemoSection';
import BlogCarousel from '../../component/blogCarousel/BlogCarousel';

class AipaSuccessStory extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="AIPA Success Story: Investing In The Future - Blogs - Noctil"
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Blogs'} />
        <div className="container">
          <div className="blogDetails">
            <div className="row">
              <div className="col-md-12">
                <img
                  src="/assets/images/blogs/1/aipa-story-investing-in-the-future-2.svg"
                  alt="AIPA Success Story: Investing In The Future"
                  className="imgfull"
                />
                <div className="author-details">
                  September 20, 2019
                  <span className="blog-details-separator">
                    <img
                      src="/assets/images/blogs/blog-details-separator.svg"
                      alt="Blog Details"
                    />
                  </span>
                  Jacob Varghese
                  <span className="blog-details-separator">
                    <img
                      src="/assets/images/blogs/blog-details-separator.svg"
                      alt="Blog Details"
                    />
                  </span>
                  4 min read
                </div>
                <div className="title">AIPA Success Story: Investing In The Future</div>
                <p>
                  AIPA is a Slovenian Collective Management Organization representing
                  Authors, Performers and Film Producers. Although the organization is at
                  the top of its game, they are continuously looking to improve efficiency
                  and accuracy with their advanced systems and streamlined processes to
                  maximize revenue for their right holders.
                </p>
                <p>
                  Like in the music industry, the movie industry has a similar challenge
                  with the data they receive. Money is always hiding under massive piles
                  of data. For example, how can we find an episode of 'Friends Season 4”
                  with a similar title or misspelled title like "The One with the Cafs" or
                  "The One with the Cats", originally titled as “The One with the Cuffs”,
                  directed by Shelly Jenson and Peter Bonerz respectively, when we have
                  very limited identification data and incorrect episodes and seasons?
                </p>
                <p>
                  Traditionally, organizations have implemented costly rule-based
                  processes and systems to identify this and solve similar problems. But
                  the real issue is, data is changing every day. Historically, this has
                  been a challenge and a headache for the head of distributions and the
                  collection department.
                </p>
                <p>
                  The leadership team at AIPA has been constantly looking for solutions to
                  get ahead in the game. Their core strengths as a Lean and Agile
                  organization enable them to quickly adapt to new opportunities. They
                  recognized the opportunity in the need for a new partnership to tackle
                  the technology side of their problem while they focus on the business
                  and process side.
                </p>
                <p>
                  In the spring of 2018, AIPA partnered with Noctil to create a proof of
                  concept using Noctil's, matchIQ, a Software as a Service solution,
                  utilizing machine learning(ML)and artificial intelligence(AI).
                </p>
                <p>
                  The first use case tried on the platform was even more interesting,
                  which was to match their member data. As an example, how can one
                  differentiate between Warner Bros, Warner Brothers, W Bros, and WB? The
                  results of the platform were strikingly precise.
                </p>
                <p>
                  <strong>
                    "As an organization that needs to tackle massive amounts of data,
                    Noctil has really given us the tools to do just that. They gave us key
                    insights into our own vast data and also helped us find a way to
                    manage it more efficiently. Partnership with Noctil is an investment
                    in the future. We are proud to be pioneering the audiovisual field
                    with them."
                  </strong>
                </p>
                <p>
                  <strong>
                    - Peter Kep<br></br>
                    Head of Professional Services
                  </strong>
                </p>
                <p>
                  Since then, AIPA has implemented many use cases on the platform,
                  exploiting the infinite possibilities of machine learning and artificial
                  intelligence thus becoming one of the few organizations leading the
                  technology innovation in this industry.
                </p>
              </div>
              <div className="col-md-12">
                <BlogCarousel />
              </div>
            </div>
          </div>
        </div>
        <div className="mt--40">
          <ScheduleADemoSection />
        </div>
      </React.Fragment>
    );
  }
}
export default AipaSuccessStory;
