import React, { Component } from 'react';
import PageHelmet from '../../component/common/Helmet';
import Breadcrumb from '../../component/common/Breadcrumb';
import ScheduleADemoSection from '../../component/scheduleADemoSection/ScheduleADemoSection';
import BlogCarousel from '../../component/blogCarousel/BlogCarousel';

class RentBuyBuild extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Rent, Buy Or Build? - Blogs - Noctil "
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Blogs'} />
        <div className="container">
          <div className="blogDetails">
            <div className="row">
              <div className="col-md-12">
                <img
                  src="/assets/images/blogs/4/rent-buy-build-2.jpeg"
                  alt="Rent, Buy Or Build?"
                  className="imgfull"
                />
                <div className="author-details">
                  October 01, 2018
                  <span className="blog-details-separator">
                    <img
                      src="/assets/images/blogs/blog-details-separator.svg"
                      alt="Blog Details"
                    />
                  </span>
                  Jacob Varghese
                  <span className="blog-details-separator">
                    <img
                      src="/assets/images/blogs/blog-details-separator.svg"
                      alt="Blog Details"
                    />
                  </span>
                  4 min read
                </div>
                <div className="title">Rent, Buy Or Build?</div>
                <p>
                  Around two decades ago, our mantra was to buy first, then build. At that
                  time there were so many custom application developments happening in the
                  industry with very few COTS (Commercial off-the-shelf) products. Many of
                  the big corporations wasted precious time and resources to build big
                  ambitious projects which then got outdated even before they were
                  released.
                </p>
                <p>
                  In the 1990s, companies started hosted applications with a single
                  licensing structure. Typically, these companies were called Application
                  Service Providers (ASP). Unfortunately, ASPs failed to deliver on the
                  promise of low cost, painless upgrades, and easy deployment. This was
                  mainly due to the technical approach of having multiple infrastructure
                  instances and code base for each account or client. In addition to this,
                  the licensing structure was not very flexible and attractive for any
                  growing business.
                </p>
                <p>
                  SaaS (Software as a Service) providers quickly learned from this failure
                  and designed a better software delivery design. Most of these products
                  have very good architecture and design to isolate the network,
                  infrastructure, and data from each tenant while being supported from a
                  single application code version.
                </p>
                <p>
                  Salesforce.com was founded in 1999, but it got real traction after 2001.
                  Salesforce was one of the very few companies that started a
                  subscription-based software renting early on in the CRM (Customer
                  Relationship Management) market space.
                </p>
                <p>
                  <strong>Innovation</strong> - SaaS platform is continuously innovating
                  and improving. Customers will get the best of the breed solution in the
                  market at any given time. These platforms are evolving continuously as
                  par with the advancement in technology.
                </p>
                <p>
                  <strong>Agility</strong> - Ability to change things faster than any
                  other alternative such as managed application, COTS, or custom
                  application development. Business is always evolving and the supporting
                  technology needs to keep up with the pace so that the business can
                  succeed in the market. A SaaS solution can easily be changed and
                  replaced.
                </p>
                <p>
                  <strong>Cost</strong> - A subscription-based model is very attractive to
                  companies. This model has the lowest TCO (Total Cost of Ownership) with
                  a relatively quick ROI (Return on Investment).
                </p>
                <p>
                  <strong>Application Management</strong> - Software upgrades and
                  deployment are all taken care of by the providers without any impact to
                  the business. Most of the SaaS providers promise very high SLA (Service
                  Level Agreements) for their offerings.
                </p>
                <p>
                  Why should you invest in a SaaS solution? There are some key advantage
                  for SaaS based solutions.
                </p>
                <p>
                  Lack of analysis and vision in leadership are resulting in custom
                  development in some of the organizations both big and small even today.
                  These organizations will have enormous disadvantages in the market due
                  to lack of agility and cost overrun. The administrative costs will have
                  a direct impact on customer acquisition and retention.
                </p>
                <p>Why should you reinvent the wheel again?</p>
              </div>
              <div className="col-md-12">
                <BlogCarousel />
              </div>
            </div>
          </div>
        </div>
        <div className="mt--40">
          <ScheduleADemoSection />
        </div>
      </React.Fragment>
    );
  }
}
export default RentBuyBuild;
