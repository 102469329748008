import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class WhatMakeNoctilDifferent extends Component {
  render() {
    return (
      <div className="container noctil-trustedpartner">
        <div className="pt--60">
          <div className="row">
            <div className="col-lg-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2 className="mb--40">What Makes Us Different</h2>
                <p className="mb--40 color-light text-left">
                  Our solution is a cloud infrastructure that stores data and is optimized
                  for functions such as matching, searching, recommendations, analytics,
                  reporting, and similar use cases, giving you the technology advantage
                  needed for the best possible outcomes.
                </p>
              </ScrollAnimation>
            </div>
            <div className="col-md-3 text-center trusted-block trusted-block-2">
              <div className="trusted-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <div className="imgouter">
                    <img
                      src="/assets/images/what-make-noctil-different/matchIQ.svg"
                      alt="About us - Noctil"
                    />
                  </div>
                  <p>
                    <strong>MatchIQ - ML-based matching Data Matching Platform</strong>
                    matchIQ matches data accurately at a low cost—increases distribution,
                    reduces administrative and operational costs, and expedites processing
                    time.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-md-3 text-center trusted-block trusted-block-2">
              <div className="trusted-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
                  <div className="imgouter">
                    <img
                      src="/assets/images/what-make-noctil-different/superior-data-management.svg"
                      alt="About us - Noctil"
                    />
                  </div>
                  <p>
                    <strong>Superior Data Management Services</strong>Our APIs help you
                    create quality master data from various sources. Plus, we offer
                    various functionalities including Transformations, Validation, and
                    Merging.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-md-3 text-center trusted-block trusted-block-2">
              <div className="trusted-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150}>
                  <div className="imgouter">
                    <img
                      src="/assets/images/what-make-noctil-different/advanced-reccom-engine.svg"
                      alt="About us - Noctil"
                    />
                  </div>
                  <p>
                    <strong>Advanced Recommendation Engine</strong>Our system helps
                    organizations with better user experience and data quality by
                    providing intelligent recommendations based on context-aware
                    algorithms.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-md-3 text-center trusted-block trusted-block-2">
              <div className="trusted-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                  <div className="imgouter">
                    <img
                      src="/assets/images/what-make-noctil-different/more-reasons.svg"
                      alt="About us - Noctil"
                    />
                  </div>
                  <p>
                    <strong>More Reasons!</strong>Our solutions come with end-to-end
                    implementation support, scalability, business agility, a better
                    cost-to-benefit ratio, and faster time to market, so you can stay a
                    step ahead.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WhatMakeNoctilDifferent;
