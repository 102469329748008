import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class OurMission extends Component {
  render() {
    return (
      <div className="subscribeNoctil bg-gradient">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>
                  Our Mission is to Boost Artist Revenue through Metadata Enrichment
                </h2>
                <p className="mt--40 mb--20">
                  Noctil aims to raise artist revenue and support organizations that serve
                  them by enhancing metadata.
                </p>
                <p>
                  Accurate and organized metadata leads to an increase of 98% in royalty
                  distribution accuracy, which can increase revenue significantly.
                </p>
              </ScrollAnimation>
            </div>
            <div className="col-md-3">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <img
                  src="/assets/images/solutions/missions.svg"
                  alt={this.props.imgAlt}
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurMission;
