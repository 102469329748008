import { useState, useEffect } from 'react';

const useGetBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = 'https://blogs.noctil.com/wp-json/wp/v2/posts?per_page=3';

    const fetchBlogs = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          alert('Error occurred while trying to get our latest blogs!');
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return { blogs, loading, error };
};

export default useGetBlogs;
