import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class SolutionDetailsBanner extends Component {
  render() {
    const { title, description, icon } = this.props.data;
    const heroImgWidth = {
      'music-metadata-identification-and-matching-software': '60%',
      'claim-accurate-and-appropriately-tool': '80%',
      'custom-portal': '80%',
    };
    const heroImgNegMarginTop = {
      'music-metadata-identification-and-matching-software': '-25px',
      'claim-accurate-and-appropriately-tool': '-30px',
    };
    return (
      <div className="container">
        <div className="homeBannerOuter">
          <div className="row">
            <div className="col-lg-6">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <p className="title">{title}</p>
                {description.split('para$').map((item) => (
                  <p key={item}>{item}</p>
                ))}
                {this.props.data?.subTitle ? (
                  <p className="sub-title">{this.props.data?.subTitle}</p>
                ) : null}
              </ScrollAnimation>
            </div>
            <div className="col-lg-6 bannerimg text-center solution-details-bannerimg">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                <img
                  src={'/assets/images/' + icon}
                  alt={this.props.imgAlt}
                  style={{
                    width: heroImgWidth[this.props.paramId] || '',
                    marginTop: heroImgNegMarginTop[this.props.paramId] || '',
                  }}
                />
              </ScrollAnimation>
            </div>
            {this.props.data?.endSubTitle ? (
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={220}>
                <p className="sub-title mt--20">{this.props.data?.endSubTitle}</p>
              </ScrollAnimation>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SolutionDetailsBanner;
