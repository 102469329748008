import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class WhatDoNoctilBelieveIn extends Component {
  render() {
    return (
      <div id="home">
        <div className="believe-in bg-gradient">
          <div className="align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                    <div>
                      <p className="title">What We Believe In</p>
                      <p className="textal-left">
                        Music is one of humanity's most universal ways of expression that
                        is ever-present in the lives of people of all countries, ages, and
                        cultures around the globe. We truly believe that music has the
                        power to transform lives and change the world! That is why Noctil
                        aims to help musicians continue to create more powerful music and
                        ensure that they are paid for their deserved rights, keeping the
                        artistic creativity alive!
                      </p>
                      <p className="textal-left">
                        <strong>
                          "As a company, we live and work by our core values, building
                          platforms that we truly believe in"
                        </strong>
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
              <div className="row mt--40">
                <div className="col-md-2 offset-md-1 believe-block">
                  <div className="believe-content">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                      <div>
                        <img
                          src="/assets/images/what-do-noctil-believe-in/integrity.svg"
                          alt="About us - Noctil"
                        />
                      </div>
                      <p>Integrity</p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-2 believe-block">
                  <div className="believe-content">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                      <div>
                        <img
                          src="/assets/images/what-do-noctil-believe-in/trust.svg"
                          alt="About us - Noctil"
                        />
                      </div>
                      <p>Trust</p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-2 believe-block">
                  <div className="believe-content">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                      <div>
                        <img
                          src="/assets/images/what-do-noctil-believe-in/passion.svg"
                          alt="About us - Noctil"
                        />
                      </div>
                      <p>Passion</p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-2 believe-block">
                  <div className="believe-content">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                      <div>
                        <img
                          src="/assets/images/what-do-noctil-believe-in/empathy.svg"
                          alt="About us - Noctil"
                        />
                      </div>
                      <p>Empathy</p>
                    </ScrollAnimation>
                  </div>
                </div>
                <div className="col-md-2 believe-block">
                  <div className="believe-content">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                      <div>
                        <img
                          src="/assets/images/what-do-noctil-believe-in/performance.svg"
                          alt="About us - Noctil"
                        />
                      </div>
                      <p>Performance</p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WhatDoNoctilBelieveIn;
