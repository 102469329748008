import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { headerSolutionsItems } from '../../data/data';
const SolutionsCard = () => {
  const history = useHistory();

  return (
    <>
      <div className="header-dropdown-card-wrapper flex-col ptb--20 plr--20">
        <div>
          <div className="mb--12 pt--5 font--20 ">BY MODULE</div>
          <div className="flex-col-start-gap--10 pb--10">
            {headerSolutionsItems.map(({ title, url }) => (
              <div
                onClick={() => {
                  history.push(url);
                }}
                className="solutions-link-card-item font--17"
              >
                {title}
              </div>
            ))}
          </div>
        </div>
        <div className="ml--20">
          <div className="mb--12 pt--5 font--20">BY SEGMENT</div>
          <div className="flex-col-start-gap--10">
            <div
              onClick={() => {
                history.push('/music-publisher-and-admins');
              }}
              className="solutions-link-card-item font--17"
            >
              Music Publishers
            </div>
            <div
              className="solutions-link-card-item font--17"
              onClick={() => {
                history.push('/record-labels');
              }}
            >
              Record Labels
            </div>
            <div
              className="solutions-link-card-item font--17"
              onClick={() => {
                history.push('/music-rights-societies');
              }}
            >
              Music Rights Societies
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionsCard;
