import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import ModalContact from '../modalContact/ModalContact';
import { MainMenu, MobileMenu, headerSolutionsItems } from '../../data/data';
import SolutionsCard from '../solutionCard/SolutionsCard';
import ResourceCard from '../resourceCard/ResourceCard';
import MobileAccordionMenu from './MobileAccordionMenu';

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    window.addEventListener('load', function () {
      console.log('');
    });
    this.state = {
      isShowSolutionsCard: false,
      isShowResourcesCard: false,
    };
  }
  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }
  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  }
  showSolutionsCard() {
    this.setState({ isShowSolutionsCard: true });
  }
  hideSolutionsCard() {
    this.setState({ isShowSolutionsCard: false });
  }
  showResourcesCard() {
    this.setState({ isShowResourcesCard: true });
  }
  hideResourcesCard() {
    this.setState({ isShowResourcesCard: false });
  }
  render() {
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle('active');
          this.classList.toggle('open');
        };
      }
    }
    const { logo, color, headerPosition } = this.props;
    let logoUrl;
    if (logo === 'original') {
      logoUrl = <img src="/assets/images/logo/noctil-logo.svg" alt="Noctil" />;
    } else {
      logoUrl = <img src="/assets/images/logo/noctil-logo.svg" alt="Noctil" />;
    }

    return (
      <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
        <div className="header-wrapper bg-white" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-6">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">{logoUrl}</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-6">
                <div className="header-right justify-content-end">
                  <nav className="mainmenunav d-lg-block desktop">
                    <ul className="mainmenu">
                      {MainMenu.map((val, i) => (
                        <li key={i}>
                          <NavLink to={`${val.link}`} exact={true}>
                            {val.Label}
                          </NavLink>
                        </li>
                      ))}
                      <li
                        onMouseOver={this.showSolutionsCard.bind(this)}
                        onMouseLeave={this.hideSolutionsCard.bind(this)}
                        className="solutions-link-btn flash"
                      >
                        <a>Solutions</a>

                        {this.state.isShowSolutionsCard ? <SolutionsCard /> : null}
                      </li>
                      <li
                        onMouseOver={this.showResourcesCard.bind(this)}
                        onMouseLeave={this.hideResourcesCard.bind(this)}
                        className="solutions-link-btn flash"
                      >
                        <a>Resources</a>
                        {this.state.isShowResourcesCard ? <ResourceCard /> : null}
                      </li>
                    </ul>
                  </nav>
                  <div className="header-btn desktop">
                    <button
                      onClick={() => this.props.onContactUsClick(this)}
                      className="custom-btn"
                      id="header-contact-us"
                    >
                      Contact Us
                    </button>
                  </div>
                  <nav className="mainmenunav d-lg-block mobile">
                    <ul className="mainmenu">
                      <div className="mobileLogo">
                        <a href="/">{logoUrl}</a>
                      </div>
                      {MobileMenu.map((val, i) => (
                        <li
                          className={`${val.class}`}
                          key={i}
                          onClick={this.CLoseMenuTrigger}
                        >
                          <NavLink to={`${val.link}`} exact={true}>
                            {val.Label}
                          </NavLink>
                        </li>
                      ))}
                      <li>
                        <MobileAccordionMenu
                          menuName={'Solutions'}
                          subMenus={[
                            ...headerSolutionsItems.map((item) => ({
                              link: item.url,
                              Label: item.title,
                            })),
                            {
                              link: '/music-publisher-and-admins',
                              Label: 'Music Publishers',
                            },
                            {
                              link: '/record-labels',
                              Label: 'Record Labels',
                            },
                            {
                              link: '/music-rights-societies',
                              Label: 'Music Rights Societies',
                            },
                          ]}
                          closeMenuTrigger={this.CLoseMenuTrigger}
                        />
                      </li>
                      <li>
                        <MobileAccordionMenu
                          menuName={'Resources'}
                          subMenus={[
                            {
                              extLink: `https://blogs.noctil.com/`,
                              Label: 'Blogs',
                            },
                            { link: '/get-use-cases', Label: 'Use Cases' },
                            { link: '/faqs', Label: "FAQ's" },
                          ]}
                          closeMenuTrigger={this.CLoseMenuTrigger}
                        />
                      </li>
                      <div className="mobile mt--40">
                        <button
                          onClick={() => this.props.onContactUsClick(this)}
                          className="custom-btn"
                        >
                          Contact Us
                        </button>
                      </div>
                    </ul>
                  </nav>
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span onClick={this.menuTrigger} className="menutrigger text-white">
                      <img
                        src="/assets/images/icons/hamburger-menu.svg"
                        alt="Noctil-Main Menu"
                      ></img>
                    </span>
                  </div>
                  <div className="close-menu d-block d-lg-none">
                    <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
