import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../component/common/Breadcrumb';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';

class Resources extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Noctil | Resources"
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Resources'} />
        <div className="mt--40">
          <ScheduleADemoSection />
        </div>
      </React.Fragment>
    );
  }
}
export default Resources;
