import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ContactUsForm from '../contactUsForm/ContactUsForm';
import GetStartedButton from '../GetStartedButton';

const SubscribeNoctil = () => {
  const { pathname } = useLocation();

  const isShowContactUs = pathname.includes('get-30-day-free-trial');

  return (
    <div className="subscribeNoctil bg-gradient mt--120">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2>
                Powering the <br /> Entertainment Industry <br /> Supply Chain
              </h2>
              <p className="mt--4">
                <b>Organize</b>, <b>transform</b>, and <b>exchange</b> creative industry
                metadata successfully
              </p>
              <h3 className="mt--80">Unlock a full month of FREE access</h3>
              {isShowContactUs ? null : (
                <div className="mt--10">
                  <GetStartedButton id="unlock-full-month-get-started" />
                </div>
              )}
            </ScrollAnimation>
          </div>
          <div className="col-md-6">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              {isShowContactUs ? (
                <ContactUsForm />
              ) : (
                <img
                  src="/assets/images/home-banner/1.png"
                  alt="Take the Best Step Forward with Noctil!"
                />
              )}
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscribeNoctil;
