import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const NoctilFeatures = () => {
  return (
    <div className="bg-lightgray">
      <div className="container noctil-features ">
        <div className="ptb--60">
          <div className="row">
            <div className="col-md-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>Our Promise</h2>
                <h3>Get Noctil's Best Features.</h3>
              </ScrollAnimation>
            </div>
            <div className="col-md-6 feature-block mt--40">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
                <div className="row d-flex">
                  <div className="col-md-6 text-center">
                    <img
                      src="/assets/images/features/get-rid-of-metadata-challenges.svg"
                      alt="Get Rid of Metadata Challenges"
                    />
                  </div>
                  <div className="col-md-6 mt--20">
                    <h4>Achieve quality metadata</h4>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-6 feature-block mt--40">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150}>
                <div className="row d-flex">
                  <div className="col-md-6 text-center">
                    <img
                      src="/assets/images/features/identify-and-match-like-never-before.svg"
                      alt="Identify and Match Like Never Before"
                    />
                  </div>
                  <div className="col-md-6 pr--0 mt--30">
                    <h4>Identify and Match Like Never Before</h4>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-6 feature-block">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                <div className="row d-flex">
                  <div className="col-md-6 text-center">
                    <img
                      src="/assets/images/features/increase-revenue-and-make-your-customers-happy.svg"
                      alt="Increase Revenue and Make Your Customers Happy"
                    />
                  </div>
                  <div className="col-md-6">
                    <h4>Increase Revenue and Make Your Customers Happy</h4>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-6 feature-block">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={250}>
                <div className="row d-flex">
                  <div className="col-md-6 text-center">
                    <img
                      src="/assets/images/features/reduce-operational-costs.svg"
                      alt="Reduce Operational Costs"
                    />
                  </div>
                  <div className="col-md-6 pr--0 mt--30">
                    <h4>Cut down operational and tech costs</h4>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-6 feature-block ">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={250}>
                <div className="row d-flex">
                  <div className="col-md-6 text-center">
                    <img
                      src="/assets/images/features/accelerate-metadata-proc-no-bg.png"
                      alt="Reduce Operational Costs"
                    />
                  </div>
                  <div className="col-md-6 pr--0 mt--30">
                    <h4>Accelerate metadata processing in bulk</h4>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-6 feature-block">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={250}>
                <div className="row d-flex gap-1">
                  <div className="col-md-6 text-center">
                    <img
                      src="/assets/images/features/empower-artist-lives-no-bg.png"
                      alt="Reduce Operational Costs"
                    />
                  </div>
                  <div className="col-md-6 pr--0 mt--60">
                    <h4>Empower Artist’s lives</h4>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          {/* <div className="flex-center">
          <span
            onClick={() => {
              history.push('/solutions');
            }}
            className="custom-btn mt--40"
          >
            Know more about our solutions
          </span>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default NoctilFeatures;
