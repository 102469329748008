import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../component/common/Breadcrumb';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';

class BlogList extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Blog - Noctil"
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Blogs'} />
        <div className="container">
          <div className="blogList">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="blogItem">
                    <a href="/aipa-success-story">
                      <img
                        src="/assets/images/blogs/1/aipa-story-investing-in-the-future-1.svg"
                        alt="AIPA Success Story: Investing In The Future"
                      />
                    </a>
                    <div className="content-outer">
                      <div className="title">
                        <a href="/aipa-success-story">
                          AIPA Success Story: Investing In The Future
                        </a>
                      </div>
                      <div className="date">September 20, 2019</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blogItem">
                    <a href="/music-repertoire-databse">
                      <img
                        src="/assets/images/blogs/2/reinventing-the-global-repertoire-db-for-the-music-industry-1.svg"
                        alt="Reinventing The Global Repertoire Database For The Music Industry"
                      />
                    </a>
                    <div className="content-outer">
                      <div className="title">
                        <a href="/music-repertoire-databse">
                          Reinventing The Global Repertoire Database For The Music
                          Industry
                        </a>
                      </div>
                      <div className="date">September 12, 2018</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blogItem">
                    <a href="blockchain-in-music">
                      <img
                        src="/assets/images/blogs/3/does-blockchain-revolutionize-the-music-industry-1.svg"
                        alt="Does Blockchain Revolutionize The Music Industry?"
                      />
                    </a>
                    <div className="content-outer">
                      <div className="title">
                        <a href="blockchain-in-music">
                          Does Blockchain Revolutionize The Music Industry?
                        </a>
                      </div>
                      <div className="date">September 04, 2018</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blogItem">
                    <a href="/rent-buy-build">
                      <img
                        src="/assets/images/blogs/4/rent-buy-build-1.svg"
                        alt="Rent, Buy Or Build?"
                      />
                    </a>
                    <div className="content-outer">
                      <div className="title">
                        <a href="/rent-buy-build">Rent, Buy Or Build?</a>
                      </div>
                      <div className="date">September 04, 2018</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt--40">
          <ScheduleADemoSection />
        </div>
      </React.Fragment>
    );
  }
}
export default BlogList;
