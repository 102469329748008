import React from 'react';
import { FaChevronRight } from 'react-icons/fa';

export const LearnMoreButton = ({ style, text = 'Learn More', onClick, id }) => {
  return (
    <button className="learn-more-button" style={style} onClick={onClick} id={id}>
      {text}
      <span>
        <FaChevronRight />
      </span>
    </button>
  );
};
