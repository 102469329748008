import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import GetStartedButton from '../GetStartedButton';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { convertSlashesToHyphens } from '../../utils';

const ScheduleADemoSection = () => {
  const { pathname } = useLocation();
  return (
    <div className="subscribeNoctil bg-gradient">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2 style={{ fontSize: '35px', lineHeight: '60px' }}>
                Are You Ready for the Evolving Music Industry? <br />
                <b>Take the Best Step Forward with Noctil!</b>
              </h2>
            </ScrollAnimation>
          </div>
          <div className="col-lg-3 d-flex align-items-center mt--20">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <GetStartedButton
                id={convertSlashesToHyphens(
                  `are-you-ready-schedule-a-demo${pathname ? pathname : ''}`
                )}
                text="Schedule a demo"
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleADemoSection;
