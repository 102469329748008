import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Retain100PercentOfRights = () => {
  return (
    <div className="bg-lightgray">
      <div className="container noctil-solutions">
        <div className="pt--60">
          <div className="row">
            <div className="col-lg-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>Retain 100% of your rightful royalties.</h2>
              </ScrollAnimation>
            </div>
          </div>
          <div className="grid-center-cols--3">
            <Card
              imgPath={'/assets/images/redesignV2/25.png'}
              text={'Works and Repertoire Management'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/26.png'}
              text={'Rights Holders Management'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/27.png'}
              text={'Document and Asset Management'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/28.png'}
              text={'Work and Repertoire Registration'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/29.png'}
              text={'Registration Reports and Status'}
            />
            <Card
              imgPath={'/assets/images/redesignV2/30.png'}
              text={'Distribution calculations & Payment reports'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retain100PercentOfRights;

export const Card = ({ imgPath, text, style }) => {
  return (
    <div className={`text-center solution-block mb--10`} style={style}>
      <div className="solution-content">
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
          <div>
            <img src={imgPath} alt={text} height={'120px'} />
          </div>
          <h4>{text}</h4>
        </ScrollAnimation>
      </div>
    </div>
  );
};
