import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaDownload } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

const data = {
  'music-rights-use-case': {
    title: 'Music Rights',
    fileUrl:
      'https://drive.google.com/uc?export=download&id=1qX7VaFiAfAGVZuGU45ge9JMb87xzsNA5',
  },
  'music-publisher-use-case': {
    title: 'Music Publishers',
    fileUrl:
      'https://drive.google.com/uc?export=download&id=1ftKSTQu7vSUgZAsmPi3WM7fYp5_FSJgQ',
  },

  'record-label-use-case': {
    title: 'Record Labels',
    fileUrl:
      'https://drive.google.com/uc?export=download&id=1pDzrOofXx8vweB8F8fVIdGI7ZVM_gdkF',
  },
};

const DownloadUseCase = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const handleDownload = () => {
    setLoading(true);

    const fileUrl = data[id]?.fileUrl || '';
    if (fileUrl) {
      setTimeout(() => {
        window.location.href = fileUrl;
        setLoading(false);
      }, 1000);
    } else {
      alert('File not found');
      setLoading(false);
    }
  };

  return (
    <div className="subscribeNoctil bg-gradient mt--80">
      <div className="container">
        <div className="ptb--60">
          <div className="row">
            <div className="col-lg-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>Download Our {data[id]?.title} Use Case Document</h2>
                {loading && <h3>Download will start shortly</h3>}
              </ScrollAnimation>
            </div>
            <div className="mx-auto mt--30">
              <div className="flex-center">
                <div>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                    <button
                      onClick={handleDownload}
                      className="custom-btn flex-row-gap--10"
                      disabled={loading}
                      id={`download-${id}-use-case`}
                    >
                      <span>Download</span>
                      {loading ? (
                        <FiLoader className="ml--10" />
                      ) : (
                        <FaDownload className="ml--10" />
                      )}
                    </button>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadUseCase;
