import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../component/common/Breadcrumb';
import { CareersData } from '../data/data';
import CareerItems from '../component/careerItems/CareerItems';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';

class Careers extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Careers - Noctil"
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Careers'} />
        <div className="solutionListOuter">
          {CareersData.map((val, i) => (
            <CareerItems {...val} key={i} />
          ))}
        </div>
        <ScheduleADemoSection />
      </React.Fragment>
    );
  }
}
export default Careers;
