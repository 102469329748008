import React, { Component } from 'react';
import PageHelmet from '../../component/common/Helmet';
import Breadcrumb from '../../component/common/Breadcrumb';
import ScheduleADemoSection from '../../component/scheduleADemoSection/ScheduleADemoSection';
import BlogCarousel from '../../component/blogCarousel/BlogCarousel';

class BlockchainInMusic extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Does Blockchain Revolutionize The Music Industry? - Blogs - Noctil"
          description="Noctil | A cloud transformation company | Music  Rights | Audio Visual Rights"
        />
        <Breadcrumb title={'Blogs'} />
        <div className="container">
          <div className="blogDetails">
            <div className="row">
              <div className="col-md-12">
                <img
                  src="/assets/images/blogs/3/does-blockchain-revolutionize-the-music-industry-2.jpeg"
                  alt="Does Blockchain Revolutionize The Music Industry?"
                  className="imgfull"
                />
                <div className="author-details">
                  September 04, 2018
                  <span className="blog-details-separator">
                    <img
                      src="/assets/images/blogs/blog-details-separator.svg"
                      alt="Blog Details"
                    />
                  </span>
                  Jacob Varghese
                  <span className="blog-details-separator">
                    <img
                      src="/assets/images/blogs/blog-details-separator.svg"
                      alt="Blog Details"
                    />
                  </span>
                  6 min read
                </div>
                <div className="title">
                  Does Blockchain Revolutionize The Music Industry?
                </div>
                <p>
                  There are hundreds of Blockchain based startups in the music industry,
                  and several opinions and blogs that have compelling arguments for it.
                </p>
                <p>
                  Blockchain technology has the potential for industry disruption that can
                  bring artists and consumers much closer.
                </p>
                <p>
                  The publishers and labels still play a vital role in promoting the
                  artist's work in the modern day production and marketing.
                </p>
                <p>
                  Our search was to understand the impact and benefit of blockchain based
                  technology in the royalty collection & distribution.
                </p>
                <p>What are they trying to solve in this industry?</p>
                <div className="service-details-inner">
                  <div className="service-details-content mb--20">
                    <div className="details">
                      <ul className="liststyle">
                        <li>Transparency</li>
                        <li>Data accuracy</li>
                        <li>Data completeness</li>
                        <li>A single view of fragmented data</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>
                  Let us view this from the angles of media creation, content
                  distribution, and consumption of economic value chain of the media
                  industry.
                </p>
                <h4>Creative Process</h4>
                <p>
                  The Song - Lyricists and composers create the work and publish this
                  through a publisher.
                </p>
                <p>
                  The Sound Recording - Labels, producer, and artists create the sound
                  recording.
                </p>
                <p>
                  Typically both of the above properties are created in two-time period
                  and in complete isolation. In other cases, it can all be part of a
                  project. Whatever the case may be, various parties hold different types
                  of rights for their creations. We need to understand how these rights
                  are documented, maintained, and used in the collection and distribution
                  process in the new models.
                </p>
                <p>
                  Information required to identify Performance Rights, Master Recording
                  Rights, and Mechanical Rights are documented in various time frames
                  during the creation, however, Neighboring Rights are an afterthought and
                  derived from the performers and producer data which is made available.
                  There are solutions that are available to collect this information at
                  the time of recording such as Auddley.
                </p>
                <p>
                  A blockchain based solution that can capture the metadata of these two
                  properties (Song and Sound Recording) and provide the ability to link
                  these together with transparency, accuracy, completeness, and a single
                  view can be a true winner.
                </p>
                <h4>Content Distribution</h4>
                <p>
                  The distribution of content has drastically changed in the last two
                  decades from mp3 to p2p sharing to music download to streaming without
                  the help of blockchain technology. These technologies have added
                  convenience to users. Now music lovers can pick and choose their
                  favorite artists and songs whenever they want to just by a swipe of
                  their finger. Music consumption is exploding but revenue is not being
                  returned fairly to those who create, own and invest in the songs.
                </p>
                <p>
                  How are these startups trying to create value in this area? Many of them
                  are trying to use the cryptocurrencies to use as the remuneration
                  medium. Some service models provide free music to consumers and mined
                  cryptocurrencies as the remuneration to the creators. This assumes the
                  long-term sustainability of a given crypto. The success of these type of
                  models truly depends on crypto economics and adoption of the currencies
                  in a highly fluctuating market.
                </p>
                <h4>Some of the blockchain based startups</h4>
                <h5 className="mb--20">For Music Streaming</h5>
                <div className="service-details-inner">
                  <div className="service-details-content mb--20">
                    <div className="details">
                      <ul className="liststyle">
                        <li>Choon - Streaming service with cryptocurrencies</li>
                        <li>PeerTracks - Streaming service using MUSE cryptocurrency</li>
                        <li>Resonate - Streaming service based on blockchain</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h5 className="mb--20">For the Creators</h5>
                <div className="service-details-inner">
                  <div className="service-details-content mb--20">
                    <div className="details">
                      <ul className="liststyle">
                        <li>Blackpool - Cryptocurrency based platform with Audiocoin</li>
                        <li>Musiconomi - Cryptocurrency based platform</li>
                        <li>Vezt - Cryptocurrency in exchange of rights</li>
                        <li>Fanmob - Cryptocurrency crowdfunding similar to Vezt</li>
                        <li>
                          Mycelia - A Creative Passport to hold creator's content and
                          metadata
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h5 className="mb--20">For Rights Management</h5>
                <div className="service-details-inner">
                  <div className="service-details-content mb--20">
                    <div className="details">
                      <ul className="liststyle">
                        <li>Blokur - Blockchain based rights management</li>
                        <li>BigchainDB - A blockchain database for rights management</li>
                        <li>
                          UJO Music - Ethereum based cryptocurrency platform to support
                          music creation and distribution.
                        </li>
                        <li>
                          Dot Blockchain Media - Blockchain based metadata and content
                          management
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p>
                  Adoption of any changes must be convenient and/or be of monetary value
                  to the users. Do any of the new blockchain based startup solutions add
                  value to the consumer so that they can change the way they purchase or
                  consume music?
                </p>
                <p>
                  There is no silver bullet to solve all the problems in the industry but
                  there are disrupters and visionaries in various parts of the industry.
                  We will have to wait and see who will survive and be successful to
                  create the standards to operate the future of the music business.
                </p>
              </div>
              <div className="col-md-12">
                <BlogCarousel />
              </div>
            </div>
          </div>
        </div>
        <div className="mt--40">
          <ScheduleADemoSection />
        </div>
      </React.Fragment>
    );
  }
}
export default BlockchainInMusic;
