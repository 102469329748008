import React from 'react';
import { Alert } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

function Toaster({ message, isOpen, handleClose }) {
  return (
    <Alert
      variant="success"
      onClose={handleClose}
      dismissible
      show={isOpen}
      style={{
        position: 'fixed',
        top: '2px',
        right: '2px',
        width: '90%',
        zIndex: 999,
        paddingRight: '16px',
      }}
    >
      <Alert.Heading>
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <h4 style={{ color: '#494949' }}>Success!!!</h4>
          <FaTimes style={{ cursor: 'pointer' }} onClick={handleClose} />
        </div>
      </Alert.Heading>
      <p>{message}</p>
    </Alert>
  );
}

export default Toaster;
