import React from 'react';
import { Accordion, Container, Card, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

const Faqs = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12 ptb--30">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2 className="text-center pt--80 mb--40">Frequently Asked Questions</h2>
            </ScrollAnimation>
            <div className="mt--20">
              <Accordion>
                <Card>
                  <Accordion.Collapse eventKey="0">
                    <Container>
                      <Card.Body>
                        Noctil platform is a no-code metadata management system that the
                        company offers to support Music and Audiovisual Rights
                        Organizations worldwide. We simplify metadata management for our
                        customers and provide 98% royalty distribution accuracy through
                        our platform. We believe fair and accurate remuneration for the
                        rights holders can be achieved by implementing the right
                        technology.
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            What is Noctil Platform?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="1">
                    <Container>
                      <Card.Body>
                        Our services are used by some of the record labels, music
                        publishers &amp; administrators and rights management societies
                        globally.
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            Who do we address in the Entertainment Industry supply chain?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="2">
                    <Container>
                      <Card.Body>
                        <p className="font--20">
                          At the core, we try to solve end-to-end metadata management
                          problems.
                        </p>
                        <p className="font--20">
                          Metadata has always been a significant problem in the music
                          industry for decades. There is no standardization of metadata
                          collection and distribution and no proper verification tools to
                          check the accuracy of a song’s metadata before it gets released.
                          Adding to it is having no unified system to store music metadata
                          in one place.
                        </p>
                        <p className="font--20">
                          Configure Noctil to meet your specific requirements and achieve
                          results faster.
                        </p>
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            What is the major problem that our solutions address?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="3">
                    <Container>
                      <Card.Body>
                        <span>
                          Currently, there are three major solutions that our platform
                          offers:
                        </span>
                        <ol className="mt--15 mb--15 flex-col-start-gap--10">
                          <li>
                            <b>DataFort</b>: Protects metadata from inconsistency,
                            inaccuracy and duplication, and transforms it into reliable
                            formats for better collaboration.
                          </li>
                          <li>
                            <b> MatchIQ: </b>Improves royalty by optimizing usage matching
                            and metadata de- duplication before any of it reaches your
                            core repository.
                          </li>
                          <li>
                            <b>ProcessEngine: </b>Offers data enrichment based on flexible
                            rules and efficiently automates complex calculations.
                          </li>
                        </ol>
                        <span>
                          Noctil platform can also be customized extensively to meet any
                          business requirements.
                        </span>
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            What are the different solutions that the Noctil platform
                            offers:
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="4">
                    <Container>
                      <Card.Body>
                        <span>Some of the features include:</span>
                        <ul className="mt--15 mb--15 flex-col-start-gap--10">
                          <li>Data integration and validation</li>
                          <li>Supports data in different languages</li>
                          <li>Data transformation</li>
                          <li>Metadata Matching &amp; De-duplication</li>
                          <li>Data Analytics and Reporting</li>
                          <li>Search engine</li>
                          <li>Audit and version history</li>
                          <li>
                            Comprehensive Security Model - Permissions/user roles can be
                            defined
                          </li>
                        </ul>
                        <span>
                          It is a no-code platform which makes it easy to use for rights
                          holders as well as industry professionals.
                        </span>
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="4">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            What are the features of our platform?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="5">
                    <Container>
                      <Card.Body>
                        We are GDPR compliant. We only collect essential information such
                        as Name, Work email, and Phone number; the data is encrypted. Once
                        the customer provides the details to our team, we take the
                        responsibility to protect client data with control mechanisms in
                        place to meet your IT security policy.
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="5">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            Is my organization’s data secure?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="6">
                    <Container>
                      <Card.Body>
                        <p className="font--20">
                          Our customer support team solves all user queries on time and
                          provides an escalation point of contact to handle any urgent
                          requirements.
                        </p>
                        <p className="font--20">
                          We also offer user manuals for your team if needed.
                        </p>
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="6">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            How are the customers supported?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="7">
                    <Container>
                      <Card.Body>
                        <p className="font--20">
                          To know more about the platform or if the offering can be
                          customized, we first need to know your requirements.
                        </p>
                        <p className="font--20">
                          Only by adding the application modules built to suit your
                          business requirements, will you be provided access to the
                          platform.
                        </p>
                        <span>Please follow the below guidelines to get in touch:</span>
                        <ul className="mt--15 mb--15 flex-col-start-gap--10">
                          <li>To fill-up the form, click on the Contact Us button</li>
                          <li>
                            Once we receive your requirement details, our team will
                            contact you through email to schedule a quick call
                          </li>
                          <li>
                            During our call, we will review your business's requirements
                            and explain our platform. In case you require a custom
                            application, we can configure a specific functionality in the
                            system for you
                          </li>
                          <li>
                            Next, a demo call would be set up to give you an in-depth view
                            of the system
                          </li>
                          <li>
                            Once you confirm and share the service agreement, you can
                            start using our service
                          </li>
                        </ul>
                        <span className="mt--20">
                          or you can even directly call us at{' '}
                          <a href="tel:+17039923990">+1 703-991-9982</a> for urgent
                          support.
                        </span>
                      </Card.Body>
                    </Container>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="7">
                    <Container>
                      <Row>
                        <Col className="col-10 px-3">
                          <div
                            className="d-flex align-items-center"
                            style={{ height: '100%' }}
                          >
                            Can the platform be customized, and how to access the
                            platform?
                          </div>
                        </Col>
                        <Col
                          className="col-2 align-self-center pl-2 py-2"
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            className="accordion-arrow"
                            src="/assets/images/icons/accordion-down-arrow.svg"
                            width="18"
                            height="18"
                            alt="chevron-right"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Toggle>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Faqs;
