import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../component/common/Breadcrumb';
import AboutBanner from '../component/aboutBanner/AboutBanner';
import OurJourney from '../component/ourJourney/OurJourney';
import WhatMakeNoctilDifferent from '../component/whatMakeNoctilDifferent/WhatMakeNoctilDifferent';
import WhatDoNoctilBelieveIn from '../component/whatDoNoctilBeliveIn/WhatDoNoctilBelieveIn';

class About extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="About Us | Best Music Data Management Software - Noctil"
          description={`Founded in 2016 as a subsidiary of Kongur Tiube LLC., Noctil has come a long way in delivering intelligent yet 
          scalable data and cloud solutions for music & audio-visual metadata management.`}
        />
        <Breadcrumb title={'About us'} />
        <AboutBanner />
        <OurJourney />
        <WhatMakeNoctilDifferent />
        <WhatDoNoctilBelieveIn />
      </React.Fragment>
    );
  }
}
export default About;
