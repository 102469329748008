import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import { SolutionsItems } from '../data/data';
import SolutionsListItems from '../component/solutionsListItems/SolutionsListItems';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';

class Solutions extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Solution | Best Digital Rights Management Software - Noctil"
          description="Improve data quality by collecting, organizing, enriching, and creating authoritative metadata of Repertoire, Works, Rights holders"
        />
        {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
          <h2 className="text-center pt--120 mb--40">Solutions</h2>
        </ScrollAnimation> */}
        {/* <Breadcrumb title={'Solutions'} /> */}
        <div className="solutionListOuter">
          {SolutionsItems.map((val, i) => (
            <SolutionsListItems {...val} key={i} />
          ))}
        </div>
        <ScheduleADemoSection />
      </React.Fragment>
    );
  }
}
export default Solutions;
