import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import CoreFeatureCard from './CoreFeatureCard';

export default class CoreFeatures extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt--40 mb--40">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2 className="mt--20 mb--40">Core Features</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <div
                className={`grid-center-cols--${
                  data.length > 3 ? data.length / 2 : data.length
                }`}
              >
                {data.map((item) => (
                  <CoreFeatureCard key={item.title} coreFeatureData={item} />
                ))}
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    );
  }
}
