import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { headerSolutionsItems } from '../../data/data';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const OtherSolutionsModules = ({ imgAlt }) => {
  const { id } = useParams();
  const history = useHistory();
  return (
    <div className="container mb--60">
      <div className="col-lg-12 text-center">
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
          <h2 className="mt--40 mb--20">Take a look at our other modules</h2>
        </ScrollAnimation>
      </div>
      <div className="row">
        {headerSolutionsItems
          .filter((item) => item.url !== `/solutions/${id}`)
          .map((item) => {
            return (
              <div
                key={item.url}
                className="col-md-4 support-block text-center other-modules-wrapper"
                onClick={() => history.push(item.url)}
              >
                <span className="other-solutions-link-card-item font--18 flex-col-center">
                  <img src={'/assets/images/' + item.icon} alt={imgAlt} />
                  <Link to={item.url}>{item.title}</Link>
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OtherSolutionsModules;
