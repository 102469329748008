import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert } from 'react-bootstrap';
import { HomeBannerBtn } from '../../data/data';

const Result = () => {
     return (
          <Alert variant="success">Your Message has been successfully sent. We will contact you soon.</Alert>
     )
}
const CaptchaError = () => {
     return (
          <Alert variant="danger">Please verify the captcha.</Alert>
     )
}
function CareerApplyNow(props) {
     const [show, setShow] = useState(false);
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
     const [result, showresult] = useState(false);
     const [captchaerror, setcaptchaerror] = useState(false);
     let captchaValue = '';
     const title = props.title;

     const setCaptcha = (value) => {
          captchaValue = value;
     }

     const sendEmail = (e) => {
          e.preventDefault();
          if (captchaValue) {
               emailjs
                    .sendForm(
                         process.env.REACT_APP_EMAILJS_SERVICE_ID,
                         process.env.REACT_APP_EMAILJS_DEMO_TEMPLATE_ID,
                         e.target,
                         process.env.REACT_APP_EMAILJS_USER_ID
                    )
                    .then((result) => {
                         console.log(result.text);
                    },
                         (error) => {
                              console.log(error.text);
                         }
                    );
               e.target.reset();
               showresult(true);
          } else {
               setcaptchaerror(true);
          }
     };

     setTimeout(() => {
          showresult(false);
          setcaptchaerror(false);
     }, 5000);

     return (
          <>
               <div>
                    { HomeBannerBtn?.Label &&
                    <Button className="custom-btn" onClick={handleShow}>
                         <span>Apply</span>
                    </Button>
                    }
               </div>

               <Modal show={show} onHide={handleClose} centered backdrop="static" size="lg" className='custom-modal' dialogClassName="my-modal">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                         <span className='title'>Apply Now</span>
                         <p>Please fill out the form to apply for the job</p>
                         <div className="contact-form--1">
                              <form action="" onSubmit={sendEmail}>
                                   <input type="hidden" id="subject" name="subject" value={title} />
                                   <div className="rn-form-group">
                                        <input
                                             type="text"
                                             name="fullname"
                                             placeholder="Name*"
                                             required
                                        />
                                   </div>

                                   <div className="rn-form-group">
                                        <input
                                             type="text"
                                             name="phone"
                                             placeholder="Phone Number*"
                                             required
                                        />
                                   </div>

                                   <div className="rn-form-group">
                                        <input
                                             type="email"
                                             name="email"
                                             placeholder="Email*"
                                             required
                                        />
                                   </div>

                                   <div className="rn-form-group">
                                        <input
                                             type="file"
                                             name="resume"
                                             placeholder="Upload Resume*"
                                             accept=".xls,.xlsx,.doc,.docx,.pages,.pdf,.txt"
                                             required
                                        />
                                   </div>

                                   <div className='rn-form-group mb--20'>
                                        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={setCaptcha}
                                        />
                                        <div className='mt--20'>
                                             {captchaerror ? <CaptchaError /> : null}
                                        </div>
                                   </div>

                                   <div className="rn-form-group">
                                        <Button className="custom-btn" type="submit" value="submit" name="submit" id="contact">
                                             <span>Send</span>
                                        </Button>
                                   </div>

                                   <div className="rn-form-group mt--40">
                                        {result ? <Result /> : null}
                                   </div>
                              </form>
                         </div>
                    </Modal.Body>
               </Modal>
          </>
     )
}
export default CareerApplyNow