import React from 'react';
import { Helmet } from 'react-helmet';
import ScrollAnimation from 'react-animate-on-scroll';
import ContactUsForm from '../component/contactUsForm/ContactUsForm';
import { FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const GetStarted = () => {
  const { pathname } = useLocation();
  const isGetUseCases = pathname.includes('get-use-cases');

  return (
    <>
      <Helmet
        pageTitle="Best Music & Audio-Visual Metadata Management Software - Noctil "
        description={`Founded in 2016 as a subsidiary of Kongur Tiube LLC. Noctil has evolved to provide music 
        industry its hidden and lost revenue through metadata. Noctil can be your all in one metadata management solution provider or 
        you can consider choosing individual modules.`}
      />
      <div className="subscribeNoctil get-started bg-gradient mt--120">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2 className="font--52">
                  {isGetUseCases
                    ? 'Explore How Noctil Can Help You Achieve More'
                    : 'Contact Our Sales Team'}
                </h2>
                <p>
                  {isGetUseCases
                    ? "We're here to help you discover solutions to elevate your productivity"
                    : "We're happy to answer questions and get you acquainted with Noctil"}
                </p>
                <div className="flex-row-gap--5">
                  <FaQuestionCircle className="text-white" />
                  <p className="font--16">
                    For product questions, please visit our{' '}
                    <Link className="text-underline text-italic text-white" to="/faqs">
                      <b>FAQ's</b>
                    </Link>
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className={`col-md-5 bg-white pt--20 form`}>
              <ContactUsForm isGetStarted />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
