import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './style.css';
import GetStartedButton from '../GetStartedButton';

const ResultsDelivered = () => {
  return (
    <div className="subscribeNoctil bg-gradient">
      <div className="container">
        <div className="ptb--30">
          <div className="row text-center">
            <div className="col-lg-12">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>Results delivered</h2>
                <h3>
                  Industry stakeholders from Music Publishing, Record Labels <br /> and
                  Music Rights Societies trust us.
                </h3>
              </ScrollAnimation>
              <div className="grid-center-cols--2 mt--50">
                <Card text={'100+ Million Sound Recordings served'} />
                <Card text={'100+ Global Collaborations'} />
                <Card text={'50,000+  Rights holders paid'} />
                <Card text={'100,000+ Catalogs Registrations done'} />
              </div>
              <div className="mt--20">
                <GetStartedButton id="results-delivered-get-started" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsDelivered;

const Card = ({ text }) => {
  return (
    <div className="ag-courses_item-wrapper w-100">
      <div class="ag-courses_item">
        <span class="ag-courses-item_link">
          <div class="ag-courses-item_bg"></div>
          <div class="ag-courses-item_title">{text}</div>
        </span>
      </div>
    </div>
  );
};
