import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Toaster from './SuccessToaster';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ContactUsForm = ({ isGetStarted = false }) => {
  const [formValues, setFormValues] = useState({
    'Last Name': '',
    Email: '',
    Designation: '',
    Company: '',
    Description: '',
  });
  const [showToaster, setShowToast] = useState({
    isShow: false,
    message: '',
  });
  const { pathname } = useLocation();
  const isGetUseCases = pathname.includes('get-use-cases');

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('Last Name', formValues['Last Name']);
    data.append('Email', formValues.Email);
    data.append('Company', formValues.Company);
    data.append(
      'Designation',
      isGetUseCases ? 'Request for use cases file' : formValues.Designation
    );
    data.append(
      'Description',
      isGetUseCases ? 'Request for use cases file' : formValues.Description
    );
    data.append(
      'xnQsjsdp',
      '6a3eac94481afa1eb6b645237b570fd9451b4faac3f4c7e9fe9011e7c7d402dd'
    );
    data.append('zc_gad', '');
    data.append(
      'xmIwtLD',
      'f8c9dabdcf0f7dc33d03f24f314e489ec18459fb7a7850f67a6478749784ee1445fc877e9934345d94f89c15e91e1de9'
    );
    data.append('actionType', 'TGVhZHM=');
    data.append('returnURL', 'null');

    try {
      const response = await axios.post('https://crm.zoho.com/crm/WebToLeadForm', data);
      console.log(response.data);
      if (response.data?.actionvalue) {
        setShowToast({
          isShow: true,
          message: isGetUseCases
            ? 'The use cases will be sent to your mail within two business working days'
            : response.data?.actionvalue,
        });
      }
      if (isGetStarted) {
        window.lintrk('track', { conversion_id: 17503876 });
      }
    } catch (error) {
      setShowToast({
        isShow: true,
        message: 'Error occur while submitting forms. Try again!',
      });
    }
  };

  return (
    <>
      <div style={{ color: isGetStarted ? '' : 'white' }} className="custom-modal">
        {isGetStarted ? null : (
          <span style={{ color: 'white' }} className="title">
            Contact Us
          </span>
        )}
        <p
          className={isGetStarted ? 'text-dark' : ''}
          style={{ color: isGetStarted ? '' : 'white' }}
        >
          Please fill out the form to{' '}
          {isGetUseCases ? 'get our uses cases' : 'get in touch with us'}
        </p>
        <div className="contact-form--1">
          <form onSubmit={handleSubmit}>
            <div className="rn-form-group">
              <label>Name*</label>
              <input
                type="text"
                name="Last Name"
                placeholder="eg: John Doe"
                onChange={handleChange}
                required
                value={formValues['Last Name']}
              />
            </div>
            <div className="rn-form-group">
              <label>Email ID*</label>
              <input
                type="text"
                name="Email"
                placeholder="eg: abc@noctil.com"
                onChange={handleChange}
                required
                value={formValues.Email}
              />
            </div>
            <div className="rn-form-group">
              <label>Company name*</label>
              <input
                type="text"
                name="Company"
                placeholder="eg: noctil ltd"
                onChange={handleChange}
                required
                value={formValues.Company}
              />
            </div>
            {!isGetUseCases ? (
              <>
                <div className="rn-form-group">
                  <label>Job title*</label>
                  <input
                    type="text"
                    name="Designation"
                    placeholder="eg: sales expert"
                    onChange={handleChange}
                    required
                    value={formValues.Designation}
                  />
                </div>
                <div className="rn-form-group">
                  <label>How did you hear about us?</label>
                  <input
                    type="text"
                    name="Description"
                    placeholder="eg: google ads"
                    onChange={handleChange}
                    value={formValues['Description']}
                  />
                </div>
              </>
            ) : null}
            <Toaster
              isOpen={showToaster.isShow}
              handleClose={() => {
                setShowToast({
                  isShow: false,
                  message: '',
                });
              }}
              message={showToaster.message}
            />
            <div className={`mx-auto ${isGetUseCases ? 'mt--50' : ''}`}>
              <Button
                className="custom-btn"
                type="submit"
                value="submit"
                name="submit"
                id="contact"
              >
                <span>Submit</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
