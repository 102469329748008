import React from 'react';
import Helmet from '../component/common/Helmet';
import NoctilSupportForRightsManagementOrg from '../component/noctilSupportForRightsManagementOrg/NoctilSupportForRightsManagementOrg';
import SubscribeNoctil from '../component/subscribeNoctil/SubscribeNoctil';
import NoctilFeatures from '../component/noctilFeatures/NoctilFeatures';
import VideoFrame from '../component/video-frame/VideoFrame';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';
import MetadataMadeSimple from '../component/metadataMadeSimple/MetadataMadeSimple';
import ResultsDelivered from '../component/resultsDelivered/ResultsDelivered';
import DiscoverPartnership from '../component/discoverPartnership/DiscoverPartnership';
import CollaborateWithGlobal from '../component/collaborateWithGlobal/CollaborateWithGlobal';
import { ResourcesForYou } from '../component/resourcesForYou/ResourcesForYou';

const Home = () => {
  return (
    <>
      <Helmet
        pageTitle="Best Music & Audio-Visual Metadata Management Software - Noctil "
        description={`Founded in 2016 as a subsidiary of Kongur Tiube LLC. Noctil has evolved to provide music 
        industry its hidden and lost revenue through metadata. Noctil can be your all in one metadata management solution provider or 
        you can consider choosing individual modules.`}
      />
      <SubscribeNoctil />
      <NoctilSupportForRightsManagementOrg />
      <MetadataMadeSimple />
      <VideoFrame />
      <ResultsDelivered />
      <DiscoverPartnership />
      <CollaborateWithGlobal title={'Collaborate with global societies using Noctil'} />
      <ResourcesForYou />
      <NoctilFeatures />
      {/* <Testimonials /> */}
      <ScheduleADemoSection />
    </>
  );
};

export default Home;
