import React, { useRef } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import ScrollToTop from 'react-scroll-up';

export default ({ children }) => {
  const footerRef = useRef(null);

  const scrollToSection = () => {
    console.log('Hii');
    window.scrollTo({
      top: footerRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Header
        onContactUsClick={scrollToSection}
        headerPosition="header--fixed logoresize"
        logo="dark"
        color="color-black"
      />
      <div className="page-container">{children}</div>
      <div ref={footerRef}>
        <Footer />
      </div>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          {/* <FiChevronUp /> */}
          <img src="/assets/images/icons/backtotop.svg" alt="Noctil" />
        </ScrollToTop>
      </div>
    </div>
  );
};
