import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

const ResourceCard = () => {
  const history = useHistory();
  return (
    <div className="header-dropdown-card-wrapper ptb--20 plr--20">
      <div className="flex-col-start-gap--10 ptb--10">
        <div
          onClick={() => {
            window.location.href = 'https://blogs.noctil.com/';
          }}
          className="solutions-link-card-item font--17"
        >
          Blogs
        </div>
        <div
          onClick={() => {
            history.push('/get-use-cases');
          }}
          className="solutions-link-card-item font--17"
        >
          Use cases
        </div>
        <div
          onClick={() => {
            history.push('/faqs');
          }}
          className="solutions-link-card-item font--17"
        >
          FAQ's
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
