import React from 'react';
import PageHelmet from '../component/common/Helmet';
import WhyChooseUs from '../component/whyChooseUs/WhyChooseUs';
import SolutionDetailsBanner from '../component/solutionDetailsBanner/SolutionDetailsBanner';
import OurMission from '../component/ourMission/OurMission';
import CoreFeatures from '../component/coreFeatures/CoreFeatures';
import OtherSolutionsModules from '../component/otherSolutionsModules/OtherSolutionsModules';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';
import { useParams } from 'react-router-dom';
import { noctilSolutionsData } from '../data/data';

const SolutionDetails = (props) => {
  const { id } = useParams();
  const data = noctilSolutionsData[id];

  return (
    <React.Fragment>
      <PageHelmet
        pageTitle={data.metadata.title || ''}
        description={data.metadata.description || ''}
      />
      <SolutionDetailsBanner
        data={data.bannerData}
        imgAlt={data.metadata.imgAlt}
        paramId={id}
      />
      <OurMission imgAlt={data.metadata.imgAlt} />
      <CoreFeatures data={data.coreFeatures} />
      <WhyChooseUs imgAlt={data.metadata.imgAlt} />
      <OtherSolutionsModules imgAlt={data.metadata.imgAlt} />
      <ScheduleADemoSection />
    </React.Fragment>
  );
};

export default SolutionDetails;
