import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import CareerApplyNow from '../careerApplyNow/CareerApplyNow';

class CareerItems extends Component {
  render() {
    const { title, icon, description } = this.props;
    return (
      <div className="container">
        <div className="row solutionItem flex-column-reverse flex-md-row">
          <div className="col-md-4 image-block">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <img src={'/assets/images/' + icon} alt={'Careers - Noctil'} />
            </ScrollAnimation>
          </div>
          <div className="col-md-8">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
              <div className="title">{title}</div>
              <p>{description}</p>
              <div className="mt--40">
                <CareerApplyNow title="abc" />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    );
  }
}
export default CareerItems;
