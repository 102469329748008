import React, { Component } from 'react';

class error404 extends Component {
     render() {
          return (
               <React.Fragment>
                    <div className="error-page-inner">
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-12">
                                        <div className="inner">
                                             <h1 className="title txt-gradient">404!</h1>
                                             <h3 className="sub-title">Page not found</h3>
                                             <p>The page you were looking for could not be found.</p>
                                             <div className="error-button">
                                                  <div><a href="/" class="custom-btn">Back To Homepage</a></div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </React.Fragment>
          )
     }
}
export default error404;
