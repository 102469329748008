import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class NoctilSupportForRightsManagementOrg extends Component {
  render() {
    return (
      <div className="noctil-support">
        <div className="container">
          <div className="ptb--60">
            <div className="row">
              <div className="col-lg-12 text-center">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <h2>All-in-one Metadata Software</h2>
                </ScrollAnimation>
              </div>
              <Card
                imgPath={'assets/images/redesignV2/2.png'}
                title={
                  <h4>
                    Ingest metadata from any <br />
                    sources, any formats
                  </h4>
                }
              />
              <Card
                imgPath={'assets/images/redesignV2/3.png'}
                title={
                  <h4 style={{ textAlign: 'left' }}>
                    Streamline workflows and <br />
                    processes to ensure
                    <br /> high-quality metadata
                  </h4>
                }
              />
              <Card
                imgPath={'assets/images/redesignV2/4.png'}
                title={
                  <h4>
                    Transform metadata into <br /> the right format
                  </h4>
                }
              />
              <Card
                imgPath={'assets/images/redesignV2/5.png'}
                title={
                  <h4>
                    Export metadata in the <br /> industry standard format
                  </h4>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NoctilSupportForRightsManagementOrg;

const Card = ({ imgPath, title }) => {
  return (
    <div className="col-md-6 mb--20">
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
        <div className="flex-row-gap--20">
          <img src={imgPath} alt="metadata" width={'120px'} height={'120px'} />
          {title}
        </div>
      </ScrollAnimation>
    </div>
  );
};
