import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ModalVideo from 'react-modal-video';
// import 'react-modal-video/css/modal-video.css';

class VideoFrame extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="noctil-videoframe">
        <div className="pt--60">
          <div className="row">
            <div className="col-lg-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>
                  Our platform is built for the <br /> Music and Audio Visual industry{' '}
                </h2>
              </ScrollAnimation>
            </div>
            <div className="col-lg-12 text-center">
              <div className="">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <div className="rn-section bg_color--1 line-separator">
                    <div>
                      <div className="row service-details-content align-items-center">
                        <div className="col-lg-12">
                          <div className="thumb position-relative">
                            <img
                              className="w-100"
                              src="/assets/images/video-frames/2.png"
                              alt="Our platform is built for the Music and Audio Visual industry"
                            />
                            <ModalVideo
                              channel="custom"
                              isOpen={this.state.isOpen}
                              url="https://player.vimeo.com/video/987170959?h=b4b411a1a5&autoplay=1&title=0&byline=0&portrait=0&transparent=0&dnt=0"
                              onClose={() => this.setState({ isOpen: false })}
                              autoplay
                            />
                            <button
                              className="video-popup position-top-center"
                              onClick={this.openModal}
                              id="video-play-button"
                            >
                              <span className="play-icon"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VideoFrame;
