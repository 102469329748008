import React, { Component } from 'react';

class CoreFeatureCard extends Component {
  render() {
    const { title, icon, description } = this.props.coreFeatureData;
    return (
      <div className="core-feature-card-wrapper">
        <h4>{title}</h4>
        <img src={'/assets/images/' + icon} alt={'Core Features - Noctil'} />
        {description.split('para$').map((item) => (
          <p style={{ textAlign: 'start' }} key={item}>
            {item}
          </p>
        ))}
      </div>
    );
  }
}

export default CoreFeatureCard;
