import React, { useState } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

function MobileAccordionMenu({ menuName, subMenus, closeMenuTrigger }) {
  const [activeItem, setActiveItem] = useState(null);

  const toggleItem = (item) => {
    if (activeItem === item) {
      setActiveItem(null);
    } else {
      setActiveItem(item);
    }
  };

  return (
    <span
      className="mobile-accordion-menu-wrapper has-submenu"
      onClick={() => toggleItem(menuName)}
    >
      {menuName}
      {activeItem === menuName && (
        <ul
          className={`submenu ${activeItem === menuName ? 'active' : ''}`}
          onClick={() => toggleItem(menuName)}
        >
          {subMenus?.map((item, i) => (
            <li onClick={() => closeMenuTrigger()} key={i}>
              {item?.link && (
                <NavLink to={item?.link} exact={true}>
                  {item.Label}
                </NavLink>
              )}
              {item.extLink && <a href={item.extLink}>{item.Label}</a>}
            </li>
          ))}
        </ul>
      )}
    </span>
  );
}

export default MobileAccordionMenu;
