import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ModalContact from '../modalContact/ModalContact';

class WhyChooseUs extends Component {
  render() {
    return (
      <div>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={40}>
          <h4 className="mb--40 mt--40 text-center">
            Noctil can be the go-to software solution for all your company’s metadata
            management needs.
          </h4>
        </ScrollAnimation>

        <div className="subscribeNoctil bg-gradient">
          <div className="container">
            <div className="col-lg-12 text-center">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                <h2>
                  Why choose <text className="us-text">us?</text>
                </h2>
              </ScrollAnimation>
            </div>
            <div className="row">
              <div className="col-lg-9">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <p className="mt--40 mb--20">
                    We aim to help musicians produce more music. And a large part of it
                    lies in enriching, organizing, and having complete metadata. Music
                    rights societies, on behalf of Rightholders, can now claim accurately
                    from all the sources of music consumption and achieve higher revenue.
                  </p>
                  <p>
                    Join us and help Musicians and Artists get fair pay and do what they
                    love, produce more music!
                  </p>
                </ScrollAnimation>
                <div className="mt--40">
                  <ModalContact />
                </div>
              </div>
              <div className="col-md-3">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
                  <img
                    src="/assets/images/solutions/why-choose-us.svg"
                    alt={this.props.imgAlt}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WhyChooseUs;
